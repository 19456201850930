import React, { useState } from "react";
import { Title } from "../style";

import { Tab, Tabs } from "@material-ui/core";
import { Evaluation } from "../Evaluation";
import { Foods } from "./Foods";
import { Diets } from "./Diets";

export function Factory({ form, setForm, step }) {
  const [tab, setTab] = useState(0);

  const evaluationConfig = [
    {
      key: "stock",
      label: "Estoque",
    },
    {
      key: "organization",
      label: "Organização",
    },
    {
      key: "storage",
      label: "Armazenamento",
    },
    {
      key: "pestsFungiImpurities",
      label: "Pres. de pragas, fungos, impurezas",
    },
    {
      key: "dietRegister",
      label: "Cadastro de dieta",
    },
    {
      key: "orderOfInclusion",
      label: "Ordem de inclusão de ingredientes",
    },
    {
      key: "predictedVsPerformed",
      label: "Previsto vs realizado/ingrediente",
    },
    {
      key: "mixingTime",
      label: "Tempo de mistura",
    },
    {
      label: "Avaliação Geral Fábrica",
    },
  ];

  return (
    <>
      <Foods form={form} setForm={setForm} />
      <Diets form={form} setForm={setForm} />
      <Title>Avaliação Fábrica</Title>
      <Tabs
        value={tab}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="on"
        onChange={(e, value) => {
          setTab(value);
        }}
      >
        {evaluationConfig.map(({ label }) => (
          <Tab label={label} />
        ))}
      </Tabs>
      <Evaluation
        {...{ form, setForm, tab, evaluationConfig, step }}
        id={form._id}
      />
    </>
  );
}
