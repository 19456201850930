import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  margin: 0;
  padding: 0 0 30px;
  border-radius: 4px;
`;

export const Main = styled.div`
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: start;
`;

export const Title = styled.h3`
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  margin-right: 10px;
  color: #434349;
  @media (max-width: 1024px) {
    font-size: 1.4rem;
  }
`;

export const Breadcrumbs = styled.div`
  display: flex;
  align-items: center;
  list-style-type: none;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  span,
  a {
    padding: 3px 0.35rem 0 0;
    font-size: 1rem;
    font-weight: 500;
    color: #959cb6;
    transition: all 0.3s;
  }
  svg {
    width: 1.5rem;
    height: 1.5rem;
    path {
      fill: #959cb6;
    }
  }
`;

export const Separator = styled.div`
  display: flex;
  align-items: center;
  padding: 3px 0.35rem 0;
  ::after {
    background: #959cb6;
    display: block;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    content: " ";
  }
`;

export const Toolbar = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  button,
  a {
    margin-left: 10px;
  }
`;
