import React from "react";
import { Button } from "../../../../../../../../../components/Button";
import { Input } from "../../../../../../../../../components/Input";
import { ButtonContent } from "../../../../../../../../../components/Table/style";
import { Col, Row, Separator } from "../../../../../../../../../styles";
import {
  convertNumberToString,
  convertStringToDate,
  getDaysBetweenDates,
  isValidDate,
} from "../../../../../../../../../utils/globalFunctions";
import { Title } from "../../../style";

export function Corral({ form, setForm, indexCorral, setIndexCorral }) {
  const getDaysOfConfinement = () => {
    return isValidDate(
      convertStringToDate(form.office.corrals[indexCorral].date)
    ) && form.office.corrals[indexCorral].date.length === 10
      ? getDaysBetweenDates(
          convertStringToDate(
            form.office.corrals[indexCorral].startOfConfinement
          ),
          convertStringToDate(form.office.corrals[indexCorral].date)
        ) + 1
      : 0;
  };

  const getPVP = () => {
    if (
      !form.office.corrals[indexCorral].averageDailyGain ||
      !form.office.corrals[indexCorral].initialWeight
    )
      return 0;

    return (
      +form.office.corrals[indexCorral].initialWeight +
      getDaysOfConfinement() *
        +form.office.corrals[indexCorral].averageDailyGain
          .toString()
          .replace(",", ".")
    );
  };

  const getPVDay = () => {
    if (!form.office.corrals[indexCorral].cmsDay) return 0;

    return (
      (
        (+form.office.corrals[indexCorral].cmsDay.toString().replace(",", ".") /
          getPVP()) *
        100
      )
        .toFixed(2)
        .replace(".", ",") + "%"
    );
  };

  const getPV = () => {
    const { cms, initialWeight } = form.office.corrals[indexCorral];
    if (!cms || !initialWeight) return 0;

    return (
      (
        (+cms.toString().replace(",", ".") /
          ((+initialWeight + getPVP()) / 2)) *
        100
      )
        .toFixed(2)
        .replace(".", ",") + "%"
    );
  };

  return (
    <>
      <Title>Editar Curral</Title>
      <Row>
        <Col>
          <Input
            type="input"
            label="Curral"
            item={form}
            setItem={setForm}
            params={`office.corrals.${indexCorral}.corral`}
          />
        </Col>
        <Col>
          <Input
            type="input"
            inputType="number"
            label="Nº Animais"
            item={form}
            setItem={setForm}
            params={`office.corrals.${indexCorral}.numberOfAnimals`}
          />
        </Col>
        <Col>
          <Input
            type="inputMask"
            mask="99/99/9999"
            label="Início do Confinamento"
            item={form}
            setItem={setForm}
            params={`office.corrals.${indexCorral}.startOfConfinement`}
          />
        </Col>
        <Col>
          <Input
            type="select"
            label="Raça"
            placeholder="Selecione a Raça"
            item={form}
            setItem={setForm}
            params={`office.corrals.${indexCorral}.breed`}
            returnKeyType="next"
            options={[
              "Nelore",
              "Gir, Guzerá e outras raças Zebuínas",
              "F1 Raças Britânicas x Nelore",
              "F1 Raças Continentais x Nelore",
              "Cruzado Zebu Holandês",
              "Canchim, Brangus, Braford, Santa Gertrudes",
              "Angus, Herelford, Devon",
              "Charolês, Chianina, Limousin",
              "Simental, Braunvieh, Gelbvieh",
              "Holandês",
            ].map((item) => ({ value: item, label: item }))}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            type="input"
            label="Peso Inicial (PI), kg"
            inputType="number"
            item={form}
            setItem={setForm}
            params={`office.corrals.${indexCorral}.initialWeight`}
          />
        </Col>
        <Col>
          <Input
            type="input"
            label="ECC, Início"
            inputType="number"
            item={form}
            setItem={setForm}
            params={`office.corrals.${indexCorral}.score`}
          />
        </Col>
        <Col>
          <Input
            type="input"
            label="GMD previsto, kg/d"
            item={form}
            setItem={setForm}
            params={`office.corrals.${indexCorral}.averageDailyGain`}
            inputType="number"
          />
        </Col>
        <Col>
          <Input
            type="inputMask"
            mask="99/99/9999"
            label="Data Atual"
            item={form}
            setItem={setForm}
            params={`office.corrals.${indexCorral}.date`}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <label>Dias em confinamento</label>
          <strong>{getDaysOfConfinement()}</strong>
        </Col>
        <Col>
          <label>PVP, kg</label>
          <strong>{convertNumberToString(getPVP(), 2)}</strong>
        </Col>
        <Col>
          <Input
            type="select"
            label="Dieta"
            placeholder="Selecione a Dieta"
            item={form}
            setItem={setForm}
            params={`office.corrals.${indexCorral}.diet`}
            options={
              form.factory.diet[0]
                ? form.factory.diet[0].diets.map(({ name }) => ({
                    value: name,
                    label: name,
                  }))
                : []
            }
          />
        </Col>
        <Col>
          <Input
            type="input"
            inputType="number"
            label="Cons. de Mat. Seca (CMS) Médio, kg/d"
            item={form}
            setItem={setForm}
            params={`office.corrals.${indexCorral}.cms`}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <label>CMS Médio, % Peso Vivo (PV)</label>
          <strong>
            {getPVP() && form.office.corrals[indexCorral].cms ? getPV() : 0}
          </strong>
        </Col>
        <Col>
          <Input
            type="input"
            label="Cons. de Mat. Seca (CMS) do Dia, kg/d"
            item={form}
            setItem={setForm}
            params={`office.corrals.${indexCorral}.cmsDay`}
            inputType="number"
          />
        </Col>
        <Col>
          <label>CMS do Dia, % Peso Vivo (PV)</label>
          <strong>
            {getPVP() && form.office.corrals[indexCorral].cmsDay
              ? getPVDay()
              : 0}
          </strong>
        </Col>
        <Col>
          <Input
            type="input"
            label="Leitura de Cocho do dia"
            item={form}
            setItem={setForm}
            params={`office.corrals.${indexCorral}.troughReading`}
            inputType="number"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            type="input"
            label="Leitura de Cocho dia anterior"
            item={form}
            setItem={setForm}
            params={`office.corrals.${indexCorral}.troughReading1`}
            inputType="number"
          />
        </Col>
        <Col>
          <Input
            type="input"
            label="Leitura de Cocho anteontem"
            item={form}
            setItem={setForm}
            params={`office.corrals.${indexCorral}.troughReading2`}
          />
        </Col>
        <Col size={2} />
      </Row>

      <ButtonContent>
        <Button
          bg="default"
          border="default"
          color="white"
          onClick={() => setIndexCorral(false)}
          style={{ marginRight: 20 }}
        >
          Salvar Curral
        </Button>
      </ButtonContent>
      <Separator />
    </>
  );
}
