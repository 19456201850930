import React, { useState } from "react";
import { Title } from "../style";
import { Tab, Tabs } from "@material-ui/core";
import { Evaluation } from "../Evaluation";
import {
  convertNumberToString,
  getPercentBulkyNDFfe,
} from "../../../../../../../utils/globalFunctions";
import {
  Body,
  Header,
  TableContent,
} from "../../../../../../../components/Table/style";
import { Input } from "../../../../../../../components/Input";
import { Col, Row } from "../../../../../../../styles";

export function BulkyNDFfe({ form, setForm, step }) {
  const [tab, setTab] = useState(0);

  const evaluationConfig = [
    {
      label: "Efetividade da  partícula do volumoso",
      key: "forageParticleEffectiveness",
    },

    {
      label: "Avaliação Geral FDNfe Volumoso",
    },
  ];

  return (
    <>
      <Row>
        <Col>
          <TableContent>
            <Header>
              <tr>
                <th>Peneira</th>
                <th>Valor obtido</th>
                <th>Valor Obtido, %</th>
              </tr>
            </Header>
            <Body>
              <tr>
                <td>Peneira 19 mm</td>
                <td>
                  <Input
                    inputType="number"
                    type="inputOnly"
                    placeholder="Valor obtido"
                    item={form}
                    setItem={setForm}
                    params={`bulkyNDFfe.d19mm`}
                  />
                </td>
                <td>
                  {convertNumberToString(
                    getPercentBulkyNDFfe("d19mm", form) * 100,
                    1
                  )}
                  %
                </td>
              </tr>
              <tr>
                <td>Peneira 8 mm</td>
                <td>
                  <Input
                    inputType="number"
                    type="inputOnly"
                    placeholder="Valor obtido"
                    item={form}
                    setItem={setForm}
                    params={`bulkyNDFfe.d8mm`}
                  />
                </td>
                <td>
                  {convertNumberToString(
                    getPercentBulkyNDFfe("d8mm", form) * 100,
                    1
                  )}
                  %
                </td>
              </tr>
              <tr>
                <td>Peneira 4 mm</td>
                <td>
                  <Input
                    inputType="number"
                    type="inputOnly"
                    placeholder="Valor obtido"
                    item={form}
                    setItem={setForm}
                    params={`bulkyNDFfe.d4mm`}
                  />
                </td>
                <td>
                  {convertNumberToString(
                    getPercentBulkyNDFfe("d4mm", form) * 100,
                    1
                  )}
                  %
                </td>
              </tr>
              <tr>
                <td>Peneira Fundo mm</td>
                <td>
                  <Input
                    inputType="number"
                    type="inputOnly"
                    placeholder="Valor obtido"
                    item={form}
                    setItem={setForm}
                    params={`bulkyNDFfe.depth`}
                  />
                </td>
                <td>
                  {convertNumberToString(
                    getPercentBulkyNDFfe("depth", form) * 100,
                    1
                  )}
                  %
                </td>
              </tr>
            </Body>
          </TableContent>
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            inputType="number"
            type="input"
            label="FDN Volumoso (%)"
            item={form}
            setItem={setForm}
            params={`bulkyNDFfe.fdn`}
          />
        </Col>
        <Col>
          <label>Efetividade Volumoso</label>
          <strong>
            {convertNumberToString(
              (getPercentBulkyNDFfe("d19mm", form) +
                getPercentBulkyNDFfe("d8mm", form) +
                getPercentBulkyNDFfe("d4mm", form)) *
                100,
              1
            )}
            %
          </strong>
        </Col>
        <Col>
          <label>FDNfe</label>
          <strong>
            {convertNumberToString(
              (getPercentBulkyNDFfe("d19mm", form) +
                getPercentBulkyNDFfe("d8mm", form) +
                getPercentBulkyNDFfe("d4mm", form)) *
                +form.bulkyNDFfe.fdn,
              1
            )}
            %
          </strong>
        </Col>
      </Row>
      <Title>Avaliação FDNfe Volumoso</Title>
      <Tabs
        value={tab}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="on"
        onChange={(e, value) => {
          setTab(value);
        }}
      >
        {evaluationConfig.map(({ label }) => (
          <Tab label={label} />
        ))}
      </Tabs>
      <Evaluation
        {...{ form, setForm, tab, evaluationConfig, step }}
        id={form._id}
      />
    </>
  );
}
