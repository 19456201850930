import React, { useState } from "react";
import { Button } from "../../../../../../../../components/Button";
import { Icons } from "../../../../../../../../components/Icons";
import {
  Body,
  Header,
  TableContent,
} from "../../../../../../../../components/Table/style";
import { Col, Row } from "../../../../../../../../styles";
import { Title } from "../../style";
import { Corral } from "./Corral";

export function Corrals({ form, setForm }) {
  const [indexCorral, setIndexCorral] = useState(false);

  const newCorralHandler = () => {
    setForm((prevState) => {
      const newState = JSON.parse(JSON.stringify(prevState));
      newState.office.corrals.push({
        corral: "",
        initialWeight: "",
        numberOfAnimals: "",
        startOfConfinement: "",
        breed: "",
        category: "",
        score: "",
        averageDailyGain: "",
        date: "",
        diet: "",
        cms: "",
        cmsDay: "",
        troughReading: "",
        troughReading1: "",
        troughReading2: "",
      });

      return newState;
    });
  };

  return indexCorral === false ? (
    <Row>
      <Col>
        <Title>Currais</Title>
        <TableContent>
          <Header>
            <tr>
              <th>Nome Curral</th>
              <th>Nº Animais</th>
              <th>Início Confinamento</th>
              <th>Raça</th>
              <th>Peso Inicial (PI), kg</th>
              <th>Editar Curral</th>
            </tr>
          </Header>
          <Body>
            {form.office.corrals.map((item, index) => (
              <tr key={index + item.corral}>
                <td>{item.corral || "Novo Curral"}</td>
                <td>{item.numberOfAnimals}</td>
                <td>{item.startOfConfinement}</td>
                <td>{item.breed}</td>
                <td>{item.initialWeight}</td>
                <td>
                  <Button
                    bg="light"
                    border="orange"
                    color="orange"
                    size="sm"
                    onClick={() => setIndexCorral(index)}
                  >
                    Editar Curral &nbsp;
                    <Icons type="edit" color="#FC8535" size={15} />
                  </Button>{" "}
                </td>
              </tr>
            ))}
            <tr>
              <td colSpan={5}></td>
              <td>
                <Button
                  bg="default"
                  border="default"
                  color="white"
                  size="sm"
                  onClick={newCorralHandler}
                >
                  Novo Curral&nbsp;
                  <Icons type="plux" color="#ffffff" size={15} />
                </Button>
              </td>
            </tr>
          </Body>
        </TableContent>
      </Col>
    </Row>
  ) : (
    <Corral {...{ form, setForm, indexCorral, setIndexCorral }} />
  );
}
