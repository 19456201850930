import React, { useState } from "react";
import { Title } from "../style";
import { Tab, Tabs } from "@material-ui/core";
import { Evaluation } from "../Evaluation";
import { EvaluationStool } from "./EvaluationStool";

export function StoolScore({ form, setForm, step }) {
  const [tab, setTab] = useState(0);

  const evaluationConfig = [
    {
      label: "Score de de fezes: 1",
      key: "s1",
    },
    {
      label: "Score de de fezes: 2",
      key: "s2",
    },
    {
      label: "Score de de fezes: 3",
      key: "s3",
    },
    {
      label: "Score de de fezes: 4",
      key: "s4",
    },
    {
      label: "Score de de fezes: 5",
      key: "s5",
    },

    {
      label: "Avaliação Geral Score de Fezes",
    },
  ];

  return (
    <>
      <Title>Avaliação Score de Fezes</Title>
      <Tabs
        value={tab}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="on"
        onChange={(e, value) => {
          setTab(value);
        }}
      >
        {evaluationConfig.map(({ label }) => (
          <Tab label={label} />
        ))}
      </Tabs>
      {tab === 5 ? (
        <Evaluation
          {...{ form, setForm, tab, evaluationConfig, step }}
          id={form._id}
        />
      ) : (
        <EvaluationStool
          {...{ form, setForm, tab, evaluationConfig, step }}
          id={form._id}
        />
      )}
    </>
  );
}
