import { useContext, useState } from "react";
import UserProfileContext from "../../../../../contexts/profile";
import Swal from "sweetalert2";
import { api } from "../../../../../services/api";
import { SubHeader } from "../../../../../components/SubHeader";
import { Icons } from "../../../../../components/Icons";
import {
  Block,
  BlockBody,
  BlockHeader,
  Col,
  Row,
  Separator,
} from "../../../../../styles";
import { Button } from "../../../../../components/Button";
import { Input } from "../../../../../components/Input";

export function Password(props) {
  const profile = useContext(UserProfileContext);

  const [user, setUser] = useState({
    password: "",
    newPassword: "",
  });

  const editPassword = async (e) => {
    e.preventDefault();
    const requestUser = { ...user };
    const response = await Swal.fire({
      title: "Editar Senha",
      text: "Deseja confirmar alteração da Senha",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0451e8",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, Editar Senha!",
      showLoaderOnConfirm: true,
      preConfirm: async () =>
        await api.post("auth/changepassword", requestUser).catch((err) => ({
          err: true,
          data: { message: err.response.data.message },
        })),
    });

    if (response.value) {
      Swal.fire({
        title: "Editar Senha",
        text: response.value.data.message,
        icon: response.value.err ? "error" : "success",
        preConfirm: () =>
          !response.value.err ? window.location.reload() : null,
      });
    }
  };

  return (
    <>
      <SubHeader
        {...props}
        title="Alterar Senha"
        breadcrumbs={[
          {
            label: "Alterar Senha",
          },
        ]}
        icon={<Icons type="password" size="24" color="#fff" />}
      />
      <Block>
        <BlockHeader>{profile.name}</BlockHeader>
        <BlockBody>
          <form onSubmit={editPassword}>
            <Row>
              <Col>
                <Input
                  item={user}
                  params="password"
                  capitalize
                  label="Senha Atual"
                  setItem={setUser}
                  type="input"
                  inputType="password"
                />
              </Col>
              <Col>
                <Input
                  item={user}
                  params="newPassword"
                  capitalize
                  label="Nova Senha"
                  setItem={setUser}
                  type="input"
                  inputType="password"
                />
              </Col>
            </Row>
            <Separator />
            <Row>
              <Col style={{ alignItems: "center" }}>
                <Button
                  type="submit"
                  color="white"
                  bg="default"
                  border="default"
                >
                  Salvar
                </Button>
              </Col>
            </Row>
          </form>
        </BlockBody>
      </Block>
    </>
  );
}
