import React from "react";
import { Icons } from "../../../../../../components/Icons";
import { SubHeader } from "../../../../../../components/SubHeader";
import { Table } from "../../../../../../components/Table";

export function ListProfiles(props) {
  const tableParams = [
    {
      key: "name",
      label: "Nome",
      filter: "text",
      placeholder: "Digite o Nome",
      mobile: {
        type: "title",
      },
    },
    {
      key: "description",
      label: "Descrição",
      mobile: {
        type: "item",
      },
    },
    {
      key: "status",
      type: "status",
      label: "Status",
      filter: "status",
      width: 150,
      mobile: {
        type: "status",
      },
    },
  ];

  return (
    <>
      <SubHeader
        {...props}
        title="Perfís"
        toolbar={["exportPDF"]}
        route="profile"
        newLabel="Novo Perfil"
        newLink="/basicregistration/profile/create"
        breadcrumbs={[{ label: "Cadastro Base" }, { label: "Perfís" }]}
        icon={<Icons type="profile" size="24" color="#fff" />}
      />
      <Table
        cols={tableParams}
        editLink={"/basicregistration/profile/edit/"}
        emptyText={"Nenhum perfil localizado!"}
        route={"profile"}
        {...props}
      />
    </>
  );
}
