import React, { useState } from "react";
import { Title } from "../style";
import { Tab, Tabs } from "@material-ui/core";
import { Evaluation } from "../Evaluation";

export function Animals({ form, setForm, step }) {
  const [tab, setTab] = useState(0);

  const evaluationConfig = [
    {
      label: "ECC",
      key: "ECC",
    },
    {
      label: "Secreção nasal",
      key: "nasalDischarge",
    },
    {
      label: "Pelo arrepiado",
      key: "ruffledHair",
    },
    {
      label: "Pelo opaco",
      key: "dullFur",
    },
    {
      label: "Durante o trato",
      key: "duringTheDeal",
    },
    {
      label: "Animais em fundo de curral",
      key: "animalsInBarnyardBackground",
    },
    {
      label: "Ruminando",
      key: "ruminating",
    },
    {
      label: "Ócio",
      key: "idleness",
    },
    {
      label: "Problemas de Casco",
      key: "hullProblems",
    },
    {
      label: "Piso do curral",
      key: "corralFloor",
    },
    {
      label: "Lama",
      key: "mud",
    },
    {
      label: "m²/boi",
      key: "m2ox",
    },
    {
      label: "Área de cocho, cm/boi",
      key: "troughAreaCmBull",
    },
    {
      label: "Pista de trato",
      key: "tractOfTreatment",
    },
    {
      label: "Avaliação Geral Animais",
    },
  ];

  return (
    <>
      <Title>Avaliação Animais</Title>
      <Tabs
        value={tab}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="on"
        onChange={(e, value) => {
          setTab(value);
        }}
      >
        {evaluationConfig.map(({ label }) => (
          <Tab label={label} />
        ))}
      </Tabs>
      <Evaluation
        {...{ form, setForm, tab, evaluationConfig, step }}
        id={form._id}
      />
    </>
  );
}
