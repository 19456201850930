import { useState, useCallback, useRef, useEffect } from "react";
import ReactLoading from "react-loading";
import { api } from "../../../services/api";
import { login } from "../../../services/auth";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";

import {
  Button,
  Connect,
  Container,
  Content,
  ContentImg,
  Form,
  InputText,
  LogoBeef,
  LogoMcassab,
  ParentVideo,
  PasswordContent,
  Video,
} from "../style";
import { PasswordIcon } from "../../../components/PasswordIcon";

export function Login(props) {
  const videoRef = useRef();

  const erroMessageInitialState = {
    message: "",
    username: false,
    password: false,
  };

  const [form, setForm] = useState({
    username: "",
    password: "",
  });

  useEffect(() => {
    setTimeout(() => {
      videoRef.current?.play();
    }, 5000);
  }, []);

  const [showPassword, setShowPassword] = useState(false);

  const [loading, setLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState(erroMessageInitialState);

  const submitData = useCallback(
    async (e) => {
      e.preventDefault();
      setErrorMessage({
        password: false,
        username: false,
      });
      if (!form.password || !form.username) {
        return setErrorMessage({
          password: !form.password,
          username: !form.username,
        });
      }
      setLoading(true);
      const response = await api.post("/auth/signin", form).catch((err) => err);
      setLoading(false);
      if (!response.data) {
        return Swal.fire("Login", "Usuário ou Senha Incorreto!", "error");
      }
      login(response.data.token);
      return props.history.push("/");
    },
    [form, props]
  );

  return (
    <ParentVideo>
      <Video ref={videoRef} playsinline autoPlay muted loop>
        <source
          src="https://zenbytes-public.sfo2.digitaloceanspaces.com/mcassab-bovinos/bg-login-mc.mp4"
          type="video/mp4"
        />
      </Video>
      <Container>
        <Content>
          <Form
            onSubmit={submitData}
            className="animate__animated animate__bounceInUp"
          >
            <ContentImg>
              <LogoMcassab
                src="https://zenbytes-public.sfo2.digitaloceanspaces.com/mcassab/logo-MCassab-hz-BRANCA.svg"
                alt="Logo MCassab"
              />
            </ContentImg>
            <Connect>
              <LogoBeef
                src="https://zenbytes-public.sfo2.digitaloceanspaces.com/mcassab/logo-BEEF.png"
                alt="logo beef"
              />
            </Connect>

            <InputText
              placeholder="E-Mail"
              isInvalid={!!errorMessage.username}
              item={form}
              setItem={setForm}
              params="username"
              type="inputOnly"
            />
            <PasswordContent>
              <InputText
                placeholder="Senha"
                isInvalid={!!errorMessage.password}
                item={form}
                setItem={setForm}
                params="password"
                type="inputOnly"
                inputType={!showPassword && "password"}
              />
              <PasswordIcon
                {...{
                  showPassword,
                  setShowPassword,
                  error: errorMessage.password,
                }}
              />
            </PasswordContent>

            <Button
              className="btn btn-primary btn-lg btn-block"
              type="submit"
              disabled={loading}
            >
              <span>
                {loading ? (
                  <ReactLoading
                    style={{
                      fill: "#fff",
                      height: "20px",
                      width: "20px",
                      display: "inline-table",
                    }}
                    type="spin"
                    color="#fff"
                    height={19}
                    width={19}
                  />
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                    style={{ marginRight: "10px" }}
                  >
                    <g
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <rect x="0" y="0" width="24" height="24" />
                      <rect
                        fill="#ffffff"
                        opacity="0.3"
                        transform="translate(9.000000, 12.000000) rotate(-270.000000) translate(-9.000000, -12.000000) "
                        x="8"
                        y="6"
                        width="2"
                        height="12"
                        rx="1"
                      />
                      <path
                        d="M20,7.00607258 C19.4477153,7.00607258 19,6.55855153 19,6.00650634 C19,5.45446114 19.4477153,5.00694009 20,5.00694009 L21,5.00694009 C23.209139,5.00694009 25,6.7970243 25,9.00520507 L25,15.001735 C25,17.2099158 23.209139,19 21,19 L9,19 C6.790861,19 5,17.2099158 5,15.001735 L5,8.99826498 C5,6.7900842 6.790861,5 9,5 L10.0000048,5 C10.5522896,5 11.0000048,5.44752105 11.0000048,5.99956624 C11.0000048,6.55161144 10.5522896,6.99913249 10.0000048,6.99913249 L9,6.99913249 C7.8954305,6.99913249 7,7.89417459 7,8.99826498 L7,15.001735 C7,16.1058254 7.8954305,17.0008675 9,17.0008675 L21,17.0008675 C22.1045695,17.0008675 23,16.1058254 23,15.001735 L23,9.00520507 C23,7.90111468 22.1045695,7.00607258 21,7.00607258 L20,7.00607258 Z"
                        fill="#ffffff"
                        fillRule="nonzero"
                        opacity="0.3"
                        transform="translate(15.000000, 12.000000) rotate(-90.000000) translate(-15.000000, -12.000000) "
                      />
                      <path
                        d="M16.7928932,9.79289322 C17.1834175,9.40236893 17.8165825,9.40236893 18.2071068,9.79289322 C18.5976311,10.1834175 18.5976311,10.8165825 18.2071068,11.2071068 L15.2071068,14.2071068 C14.8165825,14.5976311 14.1834175,14.5976311 13.7928932,14.2071068 L10.7928932,11.2071068 C10.4023689,10.8165825 10.4023689,10.1834175 10.7928932,9.79289322 C11.1834175,9.40236893 11.8165825,9.40236893 12.2071068,9.79289322 L14.5,12.0857864 L16.7928932,9.79289322 Z"
                        fill="#ffffff"
                        fillRule="nonzero"
                        transform="translate(14.500000, 12.000000) rotate(-90.000000) translate(-14.500000, -12.000000) "
                      />
                    </g>
                  </svg>
                )}{" "}
                Entrar
              </span>
            </Button>
            <Link to="/forgot">Esqueci minha senha</Link>
          </Form>
        </Content>
      </Container>
    </ParentVideo>
  );
}
