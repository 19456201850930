import { Tab, Tabs } from "@material-ui/core";
import { useState } from "react";
import { useCallback, useContext, useEffect } from "react";
import Swal from "sweetalert2";
import { Button } from "../../../../../../components/Button";
import { states } from "../../../../../../utils/globalData";
import { Icons } from "../../../../../../components/Icons";
import { Input } from "../../../../../../components/Input";
import { SubHeader } from "../../../../../../components/SubHeader";
import { ViewLoader } from "../../../../../../components/ViewLoader";
import Profile from "../../../../../../contexts/profile";
import { api } from "../../../../../../services/api";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import {
  Block,
  BlockBody,
  BlockHeader,
  Col,
  Row,
  Separator,
} from "../../../../../../styles";
import { hasPermission } from "../../../../../../utils/globalFunctions";
import ReactLoading from "react-loading";
import { Container } from "./style";
import {
  Body,
  Header,
  TableContent,
  Title,
} from "../../../../../../components/Table/style";

export function CreateEditUser(props) {
  const profile = useContext(Profile);

  const [user, setUser] = useState({
    name: "",
    username: "",
    code: "",
    responsibility: "",
    phoneNumber: "",
    state: "",
    city: "",
    status: true,
    roles: [],
    customer: null,
    inter: false,
    _id: "",
  });

  const [tab, setTab] = useState(0);

  const [profiles, setProfiles] = useState([]);

  const [indexState, setIndexState] = useState(-1);

  const [getUser, setGetUser] = useState(true);

  const [loading, setLoading] = useState(true);

  const isCustomer = useCallback(() => {
    const response = profiles.filter((item) => item.checked)[0];
    return response ? response.roles.indexOf("cliente") > -1 : false;
  }, [profiles]);

  const switchProfileHandler = useCallback(
    (e, index) => {
      const profilesCopy = JSON.parse(
        JSON.stringify(
          profiles.map((item) => {
            item.checked = false;
            return item;
          })
        )
      );
      profilesCopy[index].checked = e.target.checked;

      setProfiles(profilesCopy);
    },
    [profiles]
  );

  const resendToken = useCallback(async () => {
    const response = await Swal.fire({
      title: "Reenviar Token",
      text: "Deseja confirmar?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0451e8",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, reenviar token!",
      showLoaderOnConfirm: true,
      preConfirm: async () =>
        await api.get("user/sendtoken/" + user._id).catch((err) => ({
          err: true,
          data: { message: err.response.data.message },
        })),
    });

    if (response.value) {
      Swal.fire(
        "Reenviar Token",
        response.value.data.message,
        response.value.err ? "error" : "success"
      );
    }
  }, [user._id]);

  const selectStateHandler = useCallback(
    (e) => {
      const userCopy = { ...user };
      const value = e.target.value;
      userCopy.state =
        value !== "" ? states.estados[+value].nome.toUpperCase() : "";
      setUser(userCopy);
      setIndexState(value);
    },
    [user]
  );

  const saveUser = useCallback(
    async (e) => {
      e.preventDefault();
      if (isCustomer() && !user.customer) {
        return Swal.fire({
          title: "Criar Usuário",
          text: "Preencha o campo, Cliente Vinculado!",
          icon: "error",
        });
      }
      const requestUser = { ...user };
      profiles.forEach((profile) => {
        if (profile.checked) {
          requestUser.profile = profile._id;
          requestUser.roles = profile.roles;
        }
      });
      if (!props.match.params.id) {
        const response = await Swal.fire({
          title: "Criar Usuário",
          text: "Deseja confirmar criação do usuário",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#0451e8",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim, Criar Usuário!",
          showLoaderOnConfirm: true,
          preConfirm: async () =>
            await api.post("user", requestUser).catch((err) => ({
              err: true,
              data: { message: err.response.data.message },
            })),
        });
        if (response.value) {
          if (response.value.err) {
            return props.history.push("/404");
          }
          Swal.fire(
            "Criar Usuário",
            response.value.data.message,
            response.value.err ? "error" : "success"
          );
          props.history.replace(
            "/basicregistration/user/edit/" + response.value.data.id
          );
          setGetUser(true);
        }
      } else {
        const response = await Swal.fire({
          title: "Editar Usuário",
          text: "Deseja confirmar edição do usuário",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#0451e8",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim, Editar Usuário!",
          showLoaderOnConfirm: true,
          preConfirm: async () =>
            await api
              .put("user/" + props.match.params.id, requestUser)
              .catch((err) => ({
                err: true,
                data: { message: err.response.data.message },
              })),
        });
        if (response.value) {
          Swal.fire(
            "Editar Usuário",
            response.value.data.message,
            response.value.err ? "error" : "success"
          );
          setGetUser(true);
        }
      }
    },
    [isCustomer, profiles, props, user]
  );

  useEffect(() => {
    const loadUser = async () => {
      const userCopy = { ...user };
      const responseProfile = await api
        .get("profiles")
        .catch(() => ({ data: false }));
      if (!responseProfile.data) {
        setLoading(false);
        return props.history.push("/404");
      }
      if (props.match.params.id) {
        const responseUser = await api
          .get(`user/${props.match.params.id}`)
          .catch(() => ({ data: false }));
        if (!responseUser.data) {
          setLoading(false);
          return props.history.push("/404");
        }
        Object.keys(responseUser.data).forEach((key) => {
          userCopy[key] = responseUser.data[key];
        });
        if (userCopy.state) {
          setIndexState(
            states.estados
              .map((estado) => estado.nome.toUpperCase())
              .indexOf(userCopy.state)
          );
        }
        responseProfile.data = responseProfile.data.map((profile) => {
          profile.checked = profile._id === userCopy.profile;
          return profile;
        });
        setUser(userCopy);
      }
      setProfiles(responseProfile.data);
      setLoading(false);
    };
    if (getUser) {
      setGetUser(false);
      loadUser();
    }
  }, [getUser, user, props]);

  return (
    <>
      <ViewLoader isLoading={loading} />
      <SubHeader
        {...props}
        title="Usuário"
        breadcrumbs={[
          { label: "Cadastro Base" },
          {
            label: "Usuários",
            to: "/basicregistration/user/1/30/index/{}",
          },
          {
            label:
              (hasPermission(profile, "user", "edit")
                ? props.match.params.id
                  ? "Editar "
                  : "Novo "
                : "") + "Usuário",
          },
        ]}
        icon={<Icons type="users" size="24" color="#fff" />}
      />
      <Block className="animate__animated animate__fadeInUp">
        <BlockHeader>
          <Tabs
            value={tab}
            indicatorColor="primary"
            textColor="primary"
            onChange={(e, value) => {
              setTab(value);
            }}
          >
            <Tab label="Dados do Usuário" />
            <Tab label="Perfil do Usuário" />
            {isCustomer() && <Tab label="Cliente Vinculado" />}
          </Tabs>
          <Input
            type="switch"
            item={user}
            setItem={setUser}
            params="status"
            label="status"
            labelPlacement="start"
          />
        </BlockHeader>
        <BlockBody>
          <form onSubmit={(e) => saveUser(e)}>
            {tab === 0 && (
              <>
                <Row>
                  <Col>
                    <Row>
                      <Col style={{ paddingTop: 33 }}>
                        <Input
                          type="checkbox"
                          item={user}
                          disabled={!hasPermission(profile, "user", "edit")}
                          setItem={setUser}
                          params="inter"
                          label="Internacional"
                          placeholder="Internacional"
                          required={true}
                        />
                      </Col>
                      <Col>
                        <Input
                          type="input"
                          item={user}
                          disabled={!hasPermission(profile, "user", "edit")}
                          setItem={setUser}
                          params="code"
                          label="Código"
                          placeholder="Código"
                          required={true}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Input
                      type="input"
                      item={user}
                      disabled={!hasPermission(profile, "user", "edit")}
                      setItem={setUser}
                      params="name"
                      label="Nome"
                      placeholder="Nome"
                      capitalize={true}
                      required={true}
                    />
                  </Col>
                  <Col>
                    <Input
                      type="input"
                      inputType="email"
                      item={user}
                      disabled={!hasPermission(profile, "user", "edit")}
                      setItem={setUser}
                      params="username"
                      label="E-mail"
                      placeholder="E-mail"
                      required={true}
                    />
                  </Col>
                  {user._id && (
                    <Col>
                      <Button
                        type="button"
                        onClick={resendToken}
                        disabled={
                          loading || !hasPermission(profile, "user", "edit")
                        }
                        bg="orange"
                        border="orange"
                        color="white"
                        style={{ marginTop: "26px", width: "100%" }}
                      >
                        {loading ? (
                          <ReactLoading
                            style={{
                              fill: "#fff",
                              height: "15px",
                              width: "15px",
                              display: "inline-table",
                            }}
                            type="spin"
                            color="#fff"
                            height={19}
                            width={19}
                          />
                        ) : (
                          <Icons type="send" size={15} color="#fff" />
                        )}
                        &nbsp; Reenviar Token
                      </Button>
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col>
                    <Input
                      type="input"
                      mask={user.inter ? null : "phoneNumber"}
                      item={user}
                      disabled={!hasPermission(profile, "user", "edit")}
                      setItem={setUser}
                      params="phoneNumber"
                      label="Telefone"
                      placeholder="Telefone"
                    />
                  </Col>
                  <Col>
                    <Input
                      type="input"
                      item={user}
                      disabled={!hasPermission(profile, "user", "edit")}
                      setItem={setUser}
                      params="responsibility"
                      label="Cargo"
                      capitalize={true}
                      placeholder="Cargo"
                    />
                  </Col>
                  <Col>
                    {user.inter ? (
                      <Input
                        type="input"
                        item={user}
                        setItem={setUser}
                        params="state"
                        label="Estado"
                        disabled={!hasPermission(profile, "user", "edit")}
                        placeholder="Digite o estado"
                        options={states.estados.map((estado, index) => ({
                          value: index,
                          label: estado.nome.toUpperCase(),
                        }))}
                      />
                    ) : (
                      <Input
                        type="select"
                        onChange={(e) => selectStateHandler(e)}
                        value={indexState}
                        label="Estado"
                        disabled={!hasPermission(profile, "user", "edit")}
                        placeholder="Selecione a estado"
                        options={states.estados.map((estado, index) => ({
                          value: index,
                          label: estado.nome.toUpperCase(),
                        }))}
                      />
                    )}
                  </Col>
                  <Col>
                    <Input
                      type={user.inter ? "input" : "select"}
                      params="city"
                      item={user}
                      disabled={!hasPermission(profile, "user", "edit")}
                      setItem={setUser}
                      label="Cidade"
                      placeholder={
                        user.inter
                          ? "Digite o nome da cidade"
                          : "Selecione a cidade"
                      }
                      options={
                        indexState > -1
                          ? states.estados[+indexState].cidades.map(
                              (cidade) => ({
                                value: cidade.toUpperCase(),
                              })
                            )
                          : null
                      }
                    />
                  </Col>
                </Row>
              </>
            )}
            {tab === 1 && (
              <>
                {tab === 1 && (
                  <Container>
                    <TableContent>
                      <Header>
                        <tr>
                          <th style={{ width: "40px" }}>
                            <Title>Status</Title>
                          </th>
                          <th style={{ width: "150px" }}>
                            {" "}
                            <Title>Nome do Perfil</Title>
                          </th>
                          <th>
                            <Title>Descrição</Title>
                          </th>
                        </tr>
                      </Header>
                      <Body>
                        {profiles.map((profile, index) => (
                          <tr key={profile._id}>
                            <td>
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={profile.checked}
                                    onChange={(e) =>
                                      switchProfileHandler(e, index)
                                    }
                                    name="status"
                                  />
                                }
                              />
                            </td>
                            <td>{profile.name}</td>
                            <td>{profile.description}</td>
                          </tr>
                        ))}
                      </Body>
                    </TableContent>
                  </Container>
                )}
              </>
            )}
            {tab === 2 && (
              <Input
                item={user}
                disabled={
                  !hasPermission(profile, "user", "edit") || !isCustomer()
                }
                setItem={setUser}
                params={`customer`}
                required={isCustomer()}
                type="autocomplete"
                label="Cliente Vinculado (Somente para permissão Cliente)"
                paramsGet={["code", "name"]}
                paramsLabel={["code", "name"]}
                select={"code name"}
                model={"customer"}
                placeholder={"Digite o nome do cliente"}
              />
            )}
            <Separator />
            {hasPermission(profile, "user", "edit") ? (
              <Row>
                <Col style={{ alignItems: "center" }}>
                  <Button
                    type="submit"
                    bg="orange"
                    border="orange"
                    color="white"
                    disabled={!hasPermission(profile, "user", "edit")}
                  >
                    Salvar
                  </Button>
                </Col>
              </Row>
            ) : null}
          </form>
        </BlockBody>
      </Block>
    </>
  );
}
