import React from "react";
import Swal from "sweetalert2";
import { Button } from "../../../../../../../../components/Button";
import { Icons } from "../../../../../../../../components/Icons";
import { Input } from "../../../../../../../../components/Input";
import { Col, Row } from "../../../../../../../../styles";
import {
  ImageContent,
  ImageItem,
  ImageLink,
  ImagesContainer,
  RemoveButtom,
} from "./style";

export function EvaluationStool({
  form,
  setForm,
  evaluationConfig,
  tab,
  step,
  id,
}) {
  /* const [images, setImages] = useState({
    files: [],
  }); */

  //const [loadingImage, setLoadingImage] = useState(false);

  const removeImage = (index) => {
    Swal.fire({
      title: "Remover Imagem",
      text: "Confirmar Ação?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0451e8",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, remover imagem!",
      preConfirm: () =>
        setForm((prevState) => {
          const newState = JSON.parse(JSON.stringify(prevState));
          newState[step].evaluation[evaluationConfig[tab].key].images.splice(
            index,
            1
          );
          return newState;
        }),
    });
  };

  return (
    <>
      <Row>
        <Col>
          <Input
            type="select"
            placeholder={`Selecione a nota da avaliação`}
            label="Avaliação"
            item={form}
            setItem={setForm}
            params={`${step}.${
              evaluationConfig[tab].key
                ? `evaluation.${evaluationConfig[tab].key}.`
                : ""
            }score`}
            options={[
              {
                value: "Adequado",
                label: "Adequado",
              },

              {
                value: "Atenção",
                label: "Atenção",
              },
              {
                value: "Corrigir",
                label: "Corrigir",
              },
            ]}
          />
        </Col>
        <Col>
          <Input
            inputType="number"
            type="input"
            label="Valor obtido (%)"
            item={form}
            setItem={setForm}
            params={`${step}.${
              evaluationConfig[tab].key
                ? `evaluation.${evaluationConfig[tab].key}.`
                : ""
            }distribution`}
          />
        </Col>
        <Col>
          {evaluationConfig[tab].key && (
            <Input
              type="input"
              placeholder={`Digite a justificativa`}
              label="Justificativa"
              item={form}
              setItem={setForm}
              params={`${step}.${
                evaluationConfig[tab].key
                  ? `evaluation.${evaluationConfig[tab].key}.`
                  : ""
              }relevance`}
            />
          )}
        </Col>
      </Row>
      {evaluationConfig[tab].key && (
        <Row>
          <Col>
            <Input
              type="textarea"
              rows="6"
              placeholder={`Digite a ação a ser tomada`}
              label="Ação"
              item={form}
              setItem={setForm}
              params={`${step}.${
                evaluationConfig[tab].key
                  ? `evaluation.${evaluationConfig[tab].key}.`
                  : ""
              }action`}
            />
          </Col>
          <Col>
            {evaluationConfig[tab].key && (
              <>
                <label>Fotos</label>
                {/* <Input
              type="inputFile"
              item={images}
              setItem={setImages}
              multiple={true}
              disabled={loadingImage}
              accept="image/*"
              placeholder={
                loadingImage
                  ? "Aguarde, enviando imagens..."
                  : "Selecione as imagens"
              }
            /> */}
                <ImagesContainer>
                  {form[step].evaluation[evaluationConfig[tab].key].images
                    ? form[step].evaluation[
                        evaluationConfig[tab].key
                      ].images.map(({ fileName }, index) => (
                        <ImageContent>
                          <ImageLink
                            href={`https://zenbytes-public.sfo2.digitaloceanspaces.com/mcassab-bovinos/${id}-${fileName}`}
                          >
                            <ImageItem
                              img={`https://zenbytes-public.sfo2.digitaloceanspaces.com/mcassab-bovinos/${id}-${fileName}`}
                            />
                          </ImageLink>
                          <RemoveButtom>
                            <Button
                              bg="danger"
                              border="danger"
                              color="white"
                              size="sm"
                              onClick={() => removeImage(index)}
                              style={{
                                borderBottomRightRadius: 0,
                                borderTopLeftRadius: 0,
                              }}
                            >
                              <Icons type="trash" color="white" size={12} />
                            </Button>
                          </RemoveButtom>
                        </ImageContent>
                      ))
                    : null}
                </ImagesContainer>
              </>
            )}
          </Col>
        </Row>
      )}
    </>
  );
}
