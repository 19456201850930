import styled from "styled-components";
import { Input } from "../../components/Input";

export const ParentVideo = styled.div`
  overflow: hidden;
  position: relative;
`;

export const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: stretch;
`;

export const Content = styled.div`
  height: 100vh;
  position: absolute;
  display: flex;
  flex-direction: column;
  place-content: center;
  width: 100%;
  align-items: center;
  background-color: rgb(252 133 53 / 90%);
  background: rgb(252 133 53 / 90%); /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    rgb(0 152 112 / 70%),
    rgb(252 133 53 / 70%)
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    rgb(0 152 112 / 70%),
    rgb(252 133 53 / 70%)
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
`;

export const Form = styled.form`
  margin: 80px;
  width: 340px;
  text-align: center;
  a {
    color: #ffffff;
  }
`;

export const ContentImg = styled.div`
  margin-bottom: 2rem !important;
  justify-content: center !important;
  display: flex !important;
`;

export const Connect = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const H1 = styled.h1`
  margin-bottom: 1.5rem;
  font-family: Roboto Mono, monospace;
`;

export const LogoMcassab = styled.img`
  height: 90px;
`;

export const LogoBeef = styled.img`
  height: 60px;
  padding-bottom: 2rem;
`;

export const InputText = styled(Input)`
  margin-bottom: 15px;
  height: calc(1.5em + 2.3rem + 2px);
  padding: 1.15rem 1.65rem;
  font-size: 1.15rem;
  line-height: 1.5;
  border-radius: 3rem;
  display: block;
  width: 100%;
  font-weight: 400;
  background-clip: padding-box;
  border: 1px solid #e2e5ec;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  ${({ isInvalid }) =>
    isInvalid
      ? `
    border: 1px solid #fd397a !important;  
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23fd397a'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23fd397a' stroke='none'/%3E%3C/svg%3E") !important;
    background-repeat: no-repeat !important;    
    background-size: calc(0.75em + 0.65rem) calc(0.75em + 0.65rem) !important;  
    padding-right:  calc(1.5em + 1.3rem);
    background-position: right calc(0.375em + 0.325rem) center !important;
`
      : null}
`;

export const PasswordContent = styled.div`
  position: relative;
`;

export const Button = styled.button`
  width: 100%;
  margin-bottom: 23px;
  background-color: #d61921 !important;
  border-color: #d61921 !important;
  color: #fff;
  align-items: center;
  background: transparent;
  outline: none !important;
  display: block;
  padding: 1.15rem 1.65rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 3rem;
  font-weight: 400;
  text-align: center;
  user-select: none;
  border: 1px solid transparent;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const Label = styled.label`
  color: #fff;
  font-size: 1.5rem;
`;

export const Video = styled.video`
  width: 100%;
  position: absolute;
  top: -70%;
  left: 0;
`;
