import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

const containerCss = css`
  @media (min-width: 1025px) {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    transition: height 0.3s ease;
    position: relative;
    z-index: 2;
    height: 80px;
    border-bottom: 1px solid hsla(0, 0%, 100%, 0.1);
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
`;

export const Container = styled.div`
  ${containerCss};
  background-color: var(--orange);
`;

export const ContainerMenu = styled.div`
  ${containerCss}
  background-color:#fff;
`;

const contentCss = css`
  width: 100%;
  margin: 0;
  @media (max-width: 1024px) {
    padding: 0 15px;
  }
  @media (min-width: 1025px) {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    padding: 0 30px 15px;
  }
  @media (min-width: 1439px) {
    width: 1380px;
    margin: 0 auto;
  }
`;

export const Content = styled.div`
  ${contentCss}
`;

export const ContentMenu = styled.div`
  ${contentCss}
  display:flex;
  justify-content: start !important;
  align-items: center !important;
`;

export const ContainerMenuitem = styled.div`
  position: relative;
`;

export const MenuItem = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  min-width: 150px;
  :hover h1 {
    color: var(--orange);
  }
  :hover .border-menu-large {
    width: calc(100% + 50px);
  }
  :hover .border-menu-short {
    width: calc(100% + 25px);
  }
  h1 {
    font-size: 1.1rem;
    transition: 0.3s;
    color: ${({ isactive }) => (isactive !== "true" ? "#464e5f" : "#FC8535")};
    padding-bottom: 5px;
  }
  span {
    font-size: 0.9rem;
    color: #b5b5c3;
  }
`;

export const ActiveBorder = styled.div`
  height: 1px;
  width: ${({ isactive, index }) =>
    isactive !== "true"
      ? "0"
      : index > 0
      ? "calc(100% + 50px)"
      : "calc(100% + 25px)"};
  background-color: var(--orange);
  position: absolute;
  bottom: -21px;
  transition: 0.3s;
  left: ${({ index }) => (index > 0 ? "-25px" : "0")};
  /*  ${MenuItem}:hover & {
    width: ${({ index }) =>
    index > 0 ? "calc(100% + 50px)" : "calc(100% + 25px)"};
  } */
`;

export const Separator = styled.div`
  width: 1px;
  height: 100%;
  background-color: #b5b5c3;
  margin-right: 25px;
  margin-left: 25px;
`;

export const Brand = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: flex-begin;
  flex-direction: row;
  padding: 0;
  position: relative;
  @media (max-width: 1024px) {
    display: none;
  }
`;

export const LogoLink = styled(Link)`
  width: 100px;
  display: flex;
  justify-content: flex-begin;
  align-items: flex-end;
`;

export const Logo = styled.img`
  width: 100%;
  @media (min-width: 1025px) {
    display: inline-block;
  }
`;

export const MenuBottom = styled.div`
  background-color: #ffffff;
`;

export const DropdownMenu = styled.div`
  @media (min-width: 1024px) {
    right: auto;
    bottom: auto;
    border: 0 !important;
    min-width: 14rem;
    box-shadow: 0 0 50px 0 rgb(82 63 105 / 15%);
    padding: 1rem 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 95;
    display: none;
    float: left;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
  }

  @media (max-width: 1024px) {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    float: none;
    margin: 0;
    padding: 0;
    width: auto !important;
  }
`;

const dropdownSubMenu = css`
  outline: none !important;
  display: flex;
  flex-grow: 1;
  padding: 0.7rem 1.2rem;
  width: 100%;
  min-width: 200px;
  clear: both;
  font-weight: 400;
  color: #74788d;
  @media (min-width: 1024px) {
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    ${({ isactive }) => (isactive === "true" ? "color:#FC8535" : "")};
    &:hover {
      color: #595d6e;
      text-decoration: none;
      background-color: #f7f8fa;
      filter: brightness(1) !important;
    }
    &:active {
      color: #fc8535;
      text-decoration: none;
      background-color: #f3f3f3;
      filter: brightness(1) !important;
    }
  }
  @media (max-width: 1024px) {
    font-size: 0.9rem;
    background-color: #fbfbfb;
    ${({ isactive }) => (isactive === "true" ? "color:#FC8535" : "")};
    padding-left: 3rem;
    ${({ isselected }) => (isselected !== "true" ? "display:none" : "")}
  }
`;

export const LinkDropdown = styled(Link)`
  ${dropdownSubMenu}
`;
export const DropdownSubMenu = styled.div`
  ${dropdownSubMenu}
  align-items:center;
  justify-content: space-between;
`;

export const LinkDropdownSub = styled(Link)`
  ${dropdownSubMenu}
  @media (min-width: 1024px) {
    ${({ isactive }) => (isactive === "true" ? "color:#FC8535" : "")};
    padding-left: 2rem;
    ${({ isselected }) => (isselected !== "true" ? "display:none" : "")}
  }
  @media (max-width: 1024px) {
    padding-left: 4rem;
  }
`;
