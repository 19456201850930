import { Route, Switch, Redirect } from "react-router-dom";
import { Container, Content } from "./style";
import { CreateEditUser } from "../Views/BasicRegistration/Users/CreateEditUser";
import { ListUsers } from "../Views/BasicRegistration/Users/ListUsers";
import { CreateEditProfile } from "../Views/BasicRegistration/Profiles/CreateEditProfile";
import { ListSceneries } from "../Views/BasicRegistration/Sceneries/ListSceneries";
import { CreateEditScenery } from "../Views/BasicRegistration/Sceneries/CreateEditScenery";
import { ListProfiles } from "../Views/BasicRegistration/Profiles/ListProfiles";
import { CreateEditCustomer } from "../Views/BasicRegistration/Customers/CreateEditCustomer";
import { ListCustomers } from "../Views/BasicRegistration/Customers/ListCustomers";
import { CreateEditChecklist } from "../Views/BasicRegistration/Checklist/CreateEditChecklist";
import { ListChecklists } from "../Views/BasicRegistration/Checklist/ListChecklists";
import { Profile } from "../Views/BasicRegistration/Profile";
import { Password } from "../Views/BasicRegistration/Password";

export function Routes() {
  return (
    <Container>
      <Content>
        <Switch>
          <Route
            path="/"
            exact={true}
            render={(props) => (
              <Redirect to={`/basicregistration/checklist/1/30/-date/%7B%7D`} />
            )}
          ></Route>
          <Route path="/home" render={(props) => <div />}></Route>
          {/* User */}
          <Route
            path="/basicregistration/user/create"
            render={(props) => <CreateEditUser {...props} />}
          />
          <Route
            path="/basicregistration/user/edit/:id"
            render={(props) => <CreateEditUser {...props} />}
          />
          <Route
            path="/basicregistration/user/:page/:limit/:sort/:query"
            render={(props) => <ListUsers {...props} />}
          />
          {/* Profile */}
          <Route
            path="/basicregistration/profile/create"
            render={(props) => <CreateEditProfile {...props} />}
          />
          <Route
            path="/basicregistration/profile/edit/:id"
            render={(props) => <CreateEditProfile {...props} />}
          />
          <Route
            path="/basicregistration/profile/:page/:limit/:sort/:query"
            render={(props) => <ListProfiles {...props} />}
          />
          {/* Scenery */}
          <Route
            path="/basicregistration/scenery/create"
            render={(props) => <CreateEditScenery {...props} />}
          />
          <Route
            path="/basicregistration/scenery/edit/:id"
            render={(props) => <CreateEditScenery {...props} />}
          />
          <Route
            path="/basicregistration/scenery/:page/:limit/:sort/:query"
            render={(props) => <ListSceneries {...props} />}
          />
          {/* Customer */}
          <Route
            path="/basicregistration/customer/create"
            render={(props) => <CreateEditCustomer {...props} />}
          />
          <Route
            path="/basicregistration/customer/edit/:id"
            render={(props) => <CreateEditCustomer {...props} />}
          />
          <Route
            path="/basicregistration/customer/:page/:limit/:sort/:query"
            render={(props) => <ListCustomers {...props} />}
          />
          {/* Checklist */}
          <Route
            path="/basicregistration/checklist/create"
            render={(props) => <CreateEditChecklist {...props} />}
          />
          <Route
            path="/basicregistration/checklist/edit/:id"
            render={(props) => <CreateEditChecklist {...props} />}
          />
          <Route
            path="/basicregistration/checklist/:page/:limit/:sort/:query"
            render={(props) => <ListChecklists {...props} />}
          />
          <Route path="/profile" render={(props) => <Profile {...props} />} />
          <Route path="/password" render={(props) => <Password {...props} />} />
        </Switch>
      </Content>
    </Container>
  );
}
