import React, { useState } from "react";
import { Title } from "../style";
import { Tab, Tabs } from "@material-ui/core";
import { Evaluation } from "../Evaluation";
import { Col, Row } from "../../../../../../../styles";
import {
  Body,
  Header,
  TableContent,
} from "../../../../../../../components/Table/style";
import { Input } from "../../../../../../../components/Input";

export function GrainProcessing({ form, setForm, step }) {
  const [tab, setTab] = useState(0);

  const evaluationConfig = [
    {
      label: "Tam. médio e dist. das partículas",
      key: "averageSizeAndParticlesDistribution",
    },
    {
      label: "Avaliação Geral Processamento de grão",
    },
  ];

  return (
    <>
      <Row>
        <Col>
          <TableContent>
            <Header>
              <tr>
                <th>Peneira</th>
                <th>Valor obtido (g)</th>
                <th>Valor Referência</th>
              </tr>
            </Header>
            <Body>
              <tr>
                <td>Peneira 6 mm</td>
                <td>
                  <Input
                    inputOnly="number"
                    type="inputOnly"
                    placeholder=""
                    item={form}
                    setItem={setForm}
                    params={`grainProcessing.value6`}
                  />
                </td>
                <td>0%</td>
              </tr>
              <tr>
                <td>Peneira 3,25 mm</td>
                <td>
                  <Input
                    inputOnly="number"
                    type="inputOnly"
                    placeholder=""
                    item={form}
                    setItem={setForm}
                    params={`grainProcessing.value3`}
                  />
                </td>
                <td>30-40%</td>
              </tr>
              <tr>
                <td>Peneira 2 mm</td>
                <td>
                  <Input
                    inputOnly="number"
                    type="inputOnly"
                    placeholder=""
                    item={form}
                    setItem={setForm}
                    params={`grainProcessing.value2`}
                  />
                </td>
                <td>20-30%</td>
              </tr>
              <tr>
                <td>Peneira 1,25 mm</td>
                <td>
                  <Input
                    inputOnly="number"
                    type="inputOnly"
                    placeholder=""
                    item={form}
                    setItem={setForm}
                    params={`grainProcessing.value1`}
                  />
                </td>
                <td>10-20%</td>
              </tr>
              <tr>
                <td>Peneira Fundo</td>
                <td>
                  <Input
                    inputOnly="number"
                    type="inputOnly"
                    placeholder=""
                    item={form}
                    setItem={setForm}
                    params={`grainProcessing.valueDepth`}
                  />
                </td>
                <td>10%</td>
              </tr>
            </Body>
          </TableContent>
        </Col>
      </Row>
      <Title>Avaliação Processamento de Grãos</Title>
      <Tabs
        value={tab}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="on"
        onChange={(e, value) => {
          setTab(value);
        }}
      >
        {evaluationConfig.map(({ label }) => (
          <Tab label={label} />
        ))}
      </Tabs>
      <Evaluation
        {...{ form, setForm, tab, evaluationConfig, step }}
        id={form._id}
      />
    </>
  );
}
