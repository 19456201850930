import React, { useState, useEffect, useContext } from "react";
import { SubHeader } from "../../../../../../components/SubHeader";
import { api } from "../../../../../../services/api";
import Swal from "sweetalert2";
import UserProfileContext from "../../../../../../contexts/profile";
import { hasPermission } from "../../../../../../utils/globalFunctions";
import { Input } from "../../../../../../components/Input";
import { ViewLoader } from "../../../../../../components/ViewLoader";
import { Icons } from "../../../../../../components/Icons";
import {
  Block,
  BlockBody,
  BlockHeader,
  Col,
  Row,
  Separator,
} from "../../../../../../styles";
import { Button } from "../../../../../../components/Button";

export function CreateEditCustomer(props) {
  const profileContext = useContext(UserProfileContext);

  const [customer, setCustomer] = useState({
    code: "",
    corporateName: "",
    status: true,
    _id: "",
  });

  const [getCustomer, setGetCustomer] = useState(true);

  const [loading, setLoading] = useState(true);

  const saveCustomer = async (e) => {
    e.preventDefault();
    const requestCustomer = { ...customer };

    if (!props.match.params.id) {
      const response = await Swal.fire({
        title: "Criar Cliente",
        text: "Deseja confirmar criação do Cliente",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0451e8",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, Criar Cliente!",
        showLoaderOnConfirm: true,
        preConfirm: async () =>
          await api.post("customer", requestCustomer).catch((err) => ({
            err: true,
            data: { message: err.response.data.message },
          })),
      });
      if (response.value) {
        Swal.fire(
          "Criar Cliente",
          response.value.data.message,
          response.value.err ? "error" : "success"
        );
        if (!response.value.err) {
          props.history.replace(
            "/basicregistration/customer/edit/" + response.value.data.id
          );
          setGetCustomer(true);
        }
      }
    } else {
      const response = await Swal.fire({
        title: "Editar Cliente",
        text: "Deseja confirmar edição do Cliente",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0451e8",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, Editar Cliente!",
        showLoaderOnConfirm: true,
        preConfirm: async () =>
          await api
            .put("customer/" + props.match.params.id, requestCustomer)
            .catch((err) => ({
              err: true,
              data: { message: err.response.data.message },
            })),
      });

      if (response.value) {
        Swal.fire(
          "Editar Cliente",
          response.value.data.message,
          response.value.err ? "error" : "success"
        );
        setGetCustomer(true);
      }
    }
  };

  useEffect(() => {
    const loadCustomer = async () => {
      if (props.match.params.id) {
        setLoading(true);
        const customerCopy = { ...customer };
        const response = await api.get(`customer/${props.match.params.id}`);
        if (!response.data) {
          Swal.fire(
            "Editar Cliente",
            "Não foi possível buscar o Cliente",
            "error"
          );
        }
        Object.keys(response.data).forEach((key) => {
          customerCopy[key] = response.data[key];
        });

        setCustomer(customerCopy);
      }
      setLoading(false);
    };
    if (getCustomer) {
      setGetCustomer(false);
      loadCustomer();
    }
  }, [props.match, getCustomer, customer]);

  return (
    <>
      <ViewLoader isLoading={loading} />
      <SubHeader
        {...props}
        title="Cliente"
        breadcrumbs={[
          { label: "Cadastro Base" },
          {
            label: "Clientes",
            to: "/basicregistration/customer/1/30/index/{}",
          },
          {
            label: hasPermission(profileContext, "customer", "edit")
              ? "Cliente"
              : props.match.params.id
              ? "Editar Cliente"
              : "Novo Cliente",
          },
        ]}
        icon={<Icons type="customer" size="24" color="#fff" />}
      />
      <Block className="animate__animated animate__fadeInUp">
        <BlockHeader>
          Preencha os dados do Cliente
          <Input
            type="switch"
            item={customer}
            setItem={setCustomer}
            params="status"
            label="status"
            labelPlacement="start"
          />
        </BlockHeader>
        <BlockBody>
          <form onSubmit={(e) => saveCustomer(e)}>
            <Row>
              <Col>
                <Input
                  type="input"
                  item={customer}
                  setItem={setCustomer}
                  params="code"
                  label="Código do Cliente"
                  required={true}
                  placeholder="Código"
                />
              </Col>
              <Col>
                <Input
                  type="input"
                  item={customer}
                  setItem={setCustomer}
                  params="corporateName"
                  label="Razão Social"
                  placeholder="Razão Social do Cliente"
                  capitalize={true}
                  required={true}
                />
              </Col>
            </Row>

            <Separator />
            {hasPermission(profileContext, "customer", "edit") ? (
              <Row>
                <Col style={{ alignItems: "center" }}>
                  <Button
                    type="submit"
                    bg="orange"
                    border="orange"
                    color="white"
                    disabled={
                      !hasPermission(profileContext, "customer", "edit")
                    }
                  >
                    Salvar
                  </Button>
                </Col>
              </Row>
            ) : null}
          </form>
        </BlockBody>
      </Block>
    </>
  );
}
