import React, {
  createRef,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  ActiveBorder,
  ContainerMenu,
  ContainerMenuitem,
  ContentMenu,
  DropdownMenu,
  DropdownSubMenu,
  LinkDropdown,
  LinkDropdownSub,
  MenuItem,
  Separator,
} from "../style";
import { menuconfig } from "./menuConfig";
import Profile from "../../../../../contexts/profile";
import { hasPermission } from "../../../../../utils/globalFunctions";
import { useOutsideClick } from "../../../../../services/outsideClick";
import { useHistory } from "react-router-dom";
import { ArrowIcon } from "../../../../../components/ArrowIcon";

export default function MenuBottom({
  location,
  setShowSideMenu,
  showSideMenu,
}) {
  const ref = createRef();

  const profile = useContext(Profile);

  const history = useHistory();

  const [showDropdownMenu, setShowDropdownMenu] = useState([]);
  const [showDropdownSubMenu, setShowDropdownSubMenu] = useState(
    menuconfig.map(() => [])
  );

  useOutsideClick(ref, () => {
    setShowDropdownMenu([]);
    setShowDropdownSubMenu(menuconfig.map(() => []));
    setShowSideMenu("");
  });

  const showDropdownSubHandler = useCallback(
    (index, indexSub) =>
      setShowDropdownSubMenu((prevState) => {
        const response = menuconfig.map(() => []);
        response[index][indexSub] = !prevState[index][indexSub];
        return response;
      }),
    []
  );

  const menuItemHandler = useCallback(
    ({ to }, index) => {
      if (to) {
        setShowDropdownMenu([]);
        setShowDropdownSubMenu(menuconfig.map(() => []));
        setShowSideMenu("");
        history.push(to);
      } else {
        setShowDropdownMenu((prevState) => {
          const response = [];
          response[index] = !prevState[index];
          return response;
        });
        if (menuconfig[index].subMenu) {
          menuconfig[index].subMenu.forEach((subItem, indexSub) => {
            if (
              location.pathname.split("/")[1] === menuconfig[index].module &&
              location.pathname.split("/")[2] === subItem.module
            ) {
              setShowDropdownSubMenu((prevState) => {
                const response = menuconfig.map(() => []);
                response[index][indexSub] = !prevState[index][indexSub];
                return response;
              });
            }
          });
        }
      }
    },
    [history, setShowSideMenu, location.pathname]
  );

  useEffect(() => {
    if (showSideMenu === "true") {
      menuconfig.forEach((item, index) => {
        if (location.pathname.split("/")[1] === item.module) {
          setShowDropdownMenu((prevState) => {
            const response = [];
            response[index] = !prevState[index];
            return response;
          });

          if (item.subMenu) {
            item.subMenu.forEach((subItem, indexSub) => {
              if (location.pathname.split("/")[2] === subItem.module) {
                setShowDropdownSubMenu((prevState) => {
                  const response = menuconfig.map(() => []);
                  response[index][indexSub] = !prevState[index][indexSub];
                  return response;
                });
              }
            });
          }
        }
      });
    }
  }, [showSideMenu, location]);

  return (
    <ContainerMenu className="animate__animated animate__fadeInLeft" ref={ref}>
      <ContentMenu>
        {menuconfig.map((item, index) =>
          hasPermission(profile, item.module, "accessOnly") ? (
            <React.Fragment key={item.module}>
              <ContainerMenuitem>
                <MenuItem
                  isactive={(
                    location.pathname.split("/")[1] === item.module
                  ).toString()}
                  onClick={() => menuItemHandler(item, index)}
                  index={index}
                >
                  <h1>{item.label}</h1>
                  <span>{item.description}</span>
                  <ActiveBorder
                    className={
                      index > 0 ? "border-menu-large" : "border-menu-short"
                    }
                    isactive={(
                      location.pathname.split("/")[1] === item.module
                    ).toString()}
                    index={index}
                  />
                </MenuItem>
                <DropdownMenu
                  style={
                    showDropdownMenu[index]
                      ? {
                          position: "absolute",
                          inset: "0px auto auto 0px",
                          margin: "0px",
                          transform: "translate(-26px, 39px)",
                          top: "21px",
                          display: "block",
                        }
                      : {
                          position: "absolute",
                          top: "0px",
                          left: "0px",
                          margin: "0px",
                          opacity: "0",
                          pointerEvents: "none",
                        }
                  }
                >
                  {item.subMenu &&
                    item.subMenu.map((itemDropdown, indexDropdown) =>
                      hasPermission(
                        profile,
                        itemDropdown.module,
                        "accessOnly"
                      ) ? (
                        itemDropdown.to ? (
                          <LinkDropdown
                            key={itemDropdown.module}
                            to={itemDropdown.to}
                            onClick={() => {
                              setShowDropdownMenu([]);
                              setShowDropdownSubMenu(menuconfig.map(() => []));
                              setShowSideMenu("");
                            }}
                            isselected={showDropdownMenu[index]?.toString()}
                            isactive={(
                              location.pathname.split("/")[1] === item.module &&
                              location.pathname.split("/")[2] ===
                                itemDropdown.module
                            ).toString()}
                          >
                            {itemDropdown.label}
                          </LinkDropdown>
                        ) : (
                          <React.Fragment key={itemDropdown.module}>
                            <DropdownSubMenu
                              isselected={showDropdownMenu[index]?.toString()}
                              isactive={(
                                location.pathname.split("/")[1] ===
                                  item.module &&
                                location.pathname.split("/")[2] ===
                                  itemDropdown.module
                              ).toString()}
                              onClick={() =>
                                showDropdownSubHandler(index, indexDropdown)
                              }
                            >
                              <span>{itemDropdown.label}</span>
                              <ArrowIcon
                                show={showDropdownSubMenu[index][indexDropdown]}
                              />
                            </DropdownSubMenu>
                            {itemDropdown.subMenu.map((itemSubmenu) =>
                              hasPermission(
                                profile,
                                itemSubmenu.module,
                                "accessOnly"
                              ) ? (
                                <LinkDropdownSub
                                  key={itemSubmenu.module}
                                  to={itemSubmenu.to}
                                  onClick={() => {
                                    setShowDropdownSubMenu(
                                      menuconfig.map(() => [])
                                    );
                                    setShowDropdownMenu([]);
                                    setShowSideMenu("");
                                  }}
                                  isselected={showDropdownSubMenu[index][
                                    indexDropdown
                                  ]?.toString()}
                                  isactive={(
                                    location.pathname.split("/")[1] ===
                                      item.module &&
                                    location.pathname.split("/")[2] ===
                                      itemDropdown.module &&
                                    location.pathname.split("/")[3] ===
                                      itemSubmenu.module
                                  ).toString()}
                                >
                                  {itemSubmenu.label}
                                </LinkDropdownSub>
                              ) : null
                            )}
                          </React.Fragment>
                        )
                      ) : null
                    )}
                </DropdownMenu>
              </ContainerMenuitem>
              <Separator />
            </React.Fragment>
          ) : null
        )}
      </ContentMenu>
    </ContainerMenu>
  );
}
