import styled from "styled-components";

export const Container = styled.div`
  padding-top: 20px;
`;

export const Title = styled.h1`
  color: var(--text);
  text-align: center;
  font-size: 1.5rem;
  border-bottom: solid 1px var(--text);
  padding-bottom: 10px;
  margin-bottom: 10px;
`;
