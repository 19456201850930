import React, { useState } from "react";
import { Title } from "../style";
import { Tab, Tabs } from "@material-ui/core";
import { Evaluation } from "../Evaluation";
import { Col, Row } from "../../../../../../../styles";
import {
  Body,
  Header,
  TableContent,
} from "../../../../../../../components/Table/style";
import {
  convertNumberToString,
  getAverage,
  getStandartDeviation,
} from "../../../../../../../utils/globalFunctions";
import { Input } from "../../../../../../../components/Input";

export function MixingQuality({ form, setForm, step }) {
  const [tab, setTab] = useState(0);

  const evaluationConfig = [
    {
      label: "Qld. de mistura, início de vagão",
      key: "wagonStart",
    },
    {
      label: "Qld. de mistura, final de vagão",
      key: "wagonEnd",
    },

    {
      label: "Avaliação Geral Qualidade de Mistura",
    },
  ];

  const getCV = (wagon, mm) =>
    Math.round(
      (getStandartDeviation(form.mixingQuality[wagon][mm]) /
        getAverage(form.mixingQuality[wagon][mm])) *
        100 *
        100
    ) / 100;

  return (
    <>
      <Title>Início do Vagão</Title>
      <Row>
        <Col>
          <TableContent>
            <Header>
              <tr>
                <th>Peneira</th>
                <th>Início</th>
                <th>Meio</th>
                <th>Fim</th>
                <th>Média, gramas</th>
                <th>Desvio Padrão, gramas</th>
                <th>Coeficiente de variação, %</th>
              </tr>
            </Header>
            <Body>
              <tr>
                <td>Peneira 19 mm</td>
                <td>
                  <Input
                    inputOnly="number"
                    type="inputOnly"
                    placeholder="Início"
                    item={form}
                    setItem={setForm}
                    params={`mixingQuality.wagonStart.mm19.start`}
                  />
                </td>
                <td>
                  <Input
                    inputOnly="number"
                    type="inputOnly"
                    placeholder="Médio"
                    item={form}
                    setItem={setForm}
                    params={`mixingQuality.wagonStart.mm19.mid`}
                  />
                </td>
                <td>
                  <Input
                    inputOnly="number"
                    type="inputOnly"
                    placeholder="Fim"
                    item={form}
                    setItem={setForm}
                    params={`mixingQuality.wagonStart.mm19.end`}
                  />
                </td>
                <td>
                  {convertNumberToString(
                    getAverage(form.mixingQuality.wagonStart.mm19),
                    2
                  )}
                </td>
                <td>
                  {convertNumberToString(
                    getStandartDeviation(form.mixingQuality.wagonStart.mm19),
                    2
                  )}
                </td>
                <td>
                  {convertNumberToString(
                    isNaN(getCV("wagonStart", "mm19"))
                      ? 0
                      : getCV("wagonStart", "mm19"),
                    2
                  )}
                </td>
              </tr>
              <tr>
                <td>Peneira 8 mm</td>
                <td>
                  <Input
                    inputOnly="number"
                    type="inputOnly"
                    placeholder="Início"
                    item={form}
                    setItem={setForm}
                    params={`mixingQuality.wagonStart.mm8.start`}
                  />
                </td>
                <td>
                  <Input
                    inputOnly="number"
                    type="inputOnly"
                    placeholder="Médio"
                    item={form}
                    setItem={setForm}
                    params={`mixingQuality.wagonStart.mm8.mid`}
                  />
                </td>
                <td>
                  <Input
                    inputOnly="number"
                    type="inputOnly"
                    placeholder="Fim"
                    item={form}
                    setItem={setForm}
                    params={`mixingQuality.wagonStart.mm8.end`}
                  />
                </td>
                <td>
                  {convertNumberToString(
                    getAverage(form.mixingQuality.wagonStart.mm8),
                    2
                  )}
                </td>
                <td>
                  {convertNumberToString(
                    getStandartDeviation(form.mixingQuality.wagonStart.mm8),
                    2
                  )}
                </td>
                <td>
                  {convertNumberToString(
                    isNaN(getCV("wagonStart", "mm8"))
                      ? 0
                      : getCV("wagonStart", "mm8"),
                    2
                  )}
                </td>
              </tr>
              <tr>
                <td>Peneira 4 mm</td>
                <td>
                  <Input
                    inputOnly="number"
                    type="inputOnly"
                    placeholder="Início"
                    item={form}
                    setItem={setForm}
                    params={`mixingQuality.wagonStart.mm4.start`}
                  />
                </td>
                <td>
                  <Input
                    inputOnly="number"
                    type="inputOnly"
                    placeholder="Médio"
                    item={form}
                    setItem={setForm}
                    params={`mixingQuality.wagonStart.mm4.mid`}
                  />
                </td>
                <td>
                  <Input
                    inputOnly="number"
                    type="inputOnly"
                    placeholder="Fim"
                    item={form}
                    setItem={setForm}
                    params={`mixingQuality.wagonStart.mm4.end`}
                  />
                </td>
                <td>
                  {convertNumberToString(
                    getAverage(form.mixingQuality.wagonStart.mm4),
                    2
                  )}
                </td>
                <td>
                  {convertNumberToString(
                    getStandartDeviation(form.mixingQuality.wagonStart.mm4),
                    2
                  )}
                </td>
                <td>
                  {convertNumberToString(
                    isNaN(getCV("wagonStart", "mm4"))
                      ? 0
                      : getCV("wagonStart", "mm4"),
                    2
                  )}
                </td>
              </tr>
              <tr>
                <td>Peneira Fundo</td>
                <td>
                  <Input
                    inputOnly="number"
                    type="inputOnly"
                    placeholder="Início"
                    item={form}
                    setItem={setForm}
                    params={`mixingQuality.wagonStart.depth.start`}
                  />
                </td>
                <td>
                  <Input
                    inputOnly="number"
                    type="inputOnly"
                    placeholder="Médio"
                    item={form}
                    setItem={setForm}
                    params={`mixingQuality.wagonStart.depth.mid`}
                  />
                </td>
                <td>
                  <Input
                    inputOnly="number"
                    type="inputOnly"
                    placeholder="Fim"
                    item={form}
                    setItem={setForm}
                    params={`mixingQuality.wagonStart.depth.end`}
                  />
                </td>
                <td>
                  {convertNumberToString(
                    getAverage(form.mixingQuality.wagonStart.depth),
                    2
                  )}
                </td>
                <td>
                  {convertNumberToString(
                    getStandartDeviation(form.mixingQuality.wagonStart.depth),
                    2
                  )}
                </td>
                <td>
                  {convertNumberToString(
                    isNaN(getCV("wagonStart", "depth"))
                      ? 0
                      : getCV("wagonStart", "depth"),
                    2
                  )}
                </td>
              </tr>
            </Body>
          </TableContent>
        </Col>
      </Row>
      <Title>Final do Vagão</Title>
      <Row>
        <Col>
          <TableContent>
            <Header>
              <tr>
                <th>Peneira</th>
                <th>Início</th>
                <th>Meio</th>
                <th>Fim</th>
                <th>Média, gramas</th>
                <th>Desvio Padrão, gramas</th>
                <th>Coeficiente de variação, %</th>
              </tr>
            </Header>
            <Body>
              <tr>
                <td>Peneira 19 mm</td>
                <td>
                  <Input
                    inputOnly="number"
                    type="inputOnly"
                    placeholder="Início"
                    item={form}
                    setItem={setForm}
                    params={`mixingQuality.wagonEnd.mm19.start`}
                  />
                </td>
                <td>
                  <Input
                    inputOnly="number"
                    type="inputOnly"
                    placeholder="Médio"
                    item={form}
                    setItem={setForm}
                    params={`mixingQuality.wagonEnd.mm19.mid`}
                  />
                </td>
                <td>
                  <Input
                    inputOnly="number"
                    type="inputOnly"
                    placeholder="Fim"
                    item={form}
                    setItem={setForm}
                    params={`mixingQuality.wagonEnd.mm19.end`}
                  />
                </td>
                <td>
                  {convertNumberToString(
                    getAverage(form.mixingQuality.wagonEnd.mm19),
                    2
                  )}
                </td>
                <td>
                  {convertNumberToString(
                    getStandartDeviation(form.mixingQuality.wagonEnd.mm19),
                    2
                  )}
                </td>
                <td>
                  {convertNumberToString(
                    isNaN(getCV("wagonEnd", "mm19"))
                      ? 0
                      : getCV("wagonEnd", "mm19"),
                    2
                  )}
                </td>
              </tr>
              <tr>
                <td>Peneira 8 mm</td>
                <td>
                  <Input
                    inputOnly="number"
                    type="inputOnly"
                    placeholder="Início"
                    item={form}
                    setItem={setForm}
                    params={`mixingQuality.wagonEnd.mm8.start`}
                  />
                </td>
                <td>
                  <Input
                    inputOnly="number"
                    type="inputOnly"
                    placeholder="Médio"
                    item={form}
                    setItem={setForm}
                    params={`mixingQuality.wagonEnd.mm8.mid`}
                  />
                </td>
                <td>
                  <Input
                    inputOnly="number"
                    type="inputOnly"
                    placeholder="Fim"
                    item={form}
                    setItem={setForm}
                    params={`mixingQuality.wagonEnd.mm8.end`}
                  />
                </td>
                <td>
                  {convertNumberToString(
                    getAverage(form.mixingQuality.wagonEnd.mm8),
                    2
                  )}
                </td>
                <td>
                  {convertNumberToString(
                    getStandartDeviation(form.mixingQuality.wagonEnd.mm8),
                    2
                  )}
                </td>
                <td>
                  {convertNumberToString(
                    isNaN(getCV("wagonEnd", "mm8"))
                      ? 0
                      : getCV("wagonEnd", "mm8"),
                    2
                  )}
                </td>
              </tr>
              <tr>
                <td>Peneira 4 mm</td>
                <td>
                  <Input
                    inputOnly="number"
                    type="inputOnly"
                    placeholder="Início"
                    item={form}
                    setItem={setForm}
                    params={`mixingQuality.wagonEnd.mm4.start`}
                  />
                </td>
                <td>
                  <Input
                    inputOnly="number"
                    type="inputOnly"
                    placeholder="Médio"
                    item={form}
                    setItem={setForm}
                    params={`mixingQuality.wagonEnd.mm4.mid`}
                  />
                </td>
                <td>
                  <Input
                    inputOnly="number"
                    type="inputOnly"
                    placeholder="Fim"
                    item={form}
                    setItem={setForm}
                    params={`mixingQuality.wagonEnd.mm4.end`}
                  />
                </td>
                <td>
                  {convertNumberToString(
                    getAverage(form.mixingQuality.wagonEnd.mm4),
                    2
                  )}
                </td>
                <td>
                  {convertNumberToString(
                    getStandartDeviation(form.mixingQuality.wagonEnd.mm4),
                    2
                  )}
                </td>
                <td>
                  {convertNumberToString(
                    isNaN(getCV("wagonEnd", "mm4"))
                      ? 0
                      : getCV("wagonEnd", "mm4"),
                    2
                  )}
                </td>
              </tr>
              <tr>
                <td>Peneira Fundo</td>
                <td>
                  <Input
                    inputOnly="number"
                    type="inputOnly"
                    placeholder="Início"
                    item={form}
                    setItem={setForm}
                    params={`mixingQuality.wagonEnd.depth.start`}
                  />
                </td>
                <td>
                  <Input
                    inputOnly="number"
                    type="inputOnly"
                    placeholder="Médio"
                    item={form}
                    setItem={setForm}
                    params={`mixingQuality.wagonEnd.depth.mid`}
                  />
                </td>
                <td>
                  <Input
                    inputOnly="number"
                    type="inputOnly"
                    placeholder="Fim"
                    item={form}
                    setItem={setForm}
                    params={`mixingQuality.wagonEnd.depth.end`}
                  />
                </td>
                <td>
                  {convertNumberToString(
                    getAverage(form.mixingQuality.wagonEnd.depth),
                    2
                  )}
                </td>
                <td>
                  {convertNumberToString(
                    getStandartDeviation(form.mixingQuality.wagonEnd.depth),
                    2
                  )}
                </td>
                <td>
                  {convertNumberToString(
                    isNaN(getCV("wagonEnd", "depth"))
                      ? 0
                      : getCV("wagonEnd", "depth"),
                    2
                  )}
                </td>
              </tr>
            </Body>
          </TableContent>
        </Col>
      </Row>
      <Title>Avaliação Qualidade de Mistura</Title>
      <Tabs
        value={tab}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="on"
        onChange={(e, value) => {
          setTab(value);
        }}
      >
        {evaluationConfig.map(({ label }) => (
          <Tab label={label} />
        ))}
      </Tabs>
      <Evaluation
        {...{ form, setForm, tab, evaluationConfig, step }}
        id={form._id}
      />
    </>
  );
}
