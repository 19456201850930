import { Icons } from "../../../../../../components/Icons";
import { SubHeader } from "../../../../../../components/SubHeader";
import { Table } from "../../../../../../components/Table";

export function ListSceneries(props) {
  const tableParams = [
    {
      key: "customer",
      label: "Cliente",
      filter: "text",
      placeholder: "Digite a Razão Social",
      mobile: {
        type: "title",
      },
    },
    {
      key: "farmName",
      label: "Nome da Fazenda",
      filter: "text",
      placeholder: "Digite o Nome da Fazenda",
      mobile: {
        type: "title",
      },
    },
    {
      key: "owner",
      label: "Proprietário",
      filter: "text",
      placeholder: "Digite o proprietário",
      mobile: {
        type: "item",
      },
    },
    {
      key: "numberOfAnimals",
      label: "Nº Animais",
      filter: "range",
      placeholder: "Digite número de animais",
      mobile: {
        type: "small",
        label: true,
      },
    },
    {
      key: "city",
      label: "Cidade",
      filter: "text",
      placeholder: "Digite a Cidade",
      mobile: {
        type: "item",
      },
    },
    {
      key: "state",
      label: "Estado",
      filter: "text",
      placeholder: "Digite o Estado",
      mobile: {
        type: "item",
      },
    },

    {
      key: "status",
      type: "status",
      label: "Status",
      width: 150,
      filter: "status",
      mobile: {
        type: "status",
      },
    },
  ];

  return (
    <>
      <SubHeader
        {...props}
        title="Cenários"
        toolbar={["exportPDF"]}
        route="scenery"
        newLabel="Novo Cenário"
        newLink="/basicregistration/scenery/create"
        breadcrumbs={[
          { label: "Cadastro Base" },
          {
            label: "Cenários",
          },
        ]}
        icon={<Icons type="customer" size="24" color="#fff" />}
      />
      <Table
        cols={tableParams}
        editLink={"/basicregistration/scenery/edit/"}
        emptyText={"Nenhum usuário localizado!"}
        route={"scenery"}
        {...props}
      />
    </>
  );
}
