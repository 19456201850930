import React, { useState } from "react";
import { Button } from "../../../../../../../../components/Button";
import { Icons } from "../../../../../../../../components/Icons";
import {
  Body,
  Header,
  TableContent,
} from "../../../../../../../../components/Table/style";
import { Col, Row } from "../../../../../../../../styles";
import { convertNumberToString } from "../../../../../../../../utils/globalFunctions";
import { Title } from "../../style";
import { Diet } from "./Diet";

export function Diets({ form, setForm }) {
  const [indexDiet, setIndexDiet] = useState(false);
  const newDietHandler = () => {
    setForm((prevState) => {
      const newState = JSON.parse(JSON.stringify(prevState));
      newState.factory.diet = newState.factory.diet.map((item) => {
        item.diets.push({
          name: "",
          value: "",
          elg: "",
          elm: "",
          costFinalDiet: "",
        });
        return item;
      });

      return newState;
    });
  };
  return indexDiet === false ? (
    <Row>
      <Col>
        <Title>Dietas</Title>
        <TableContent>
          <Header>
            <tr>
              <th>Nome da Dieta</th>
              <th>Elg mcal/kg</th>
              <th>Elm mca/kg</th>
              <th>Custo kg/MS da Dieta</th>
              <th style={{ width: 180 }}>Editar Dieta</th>
            </tr>
          </Header>
          <Body>
            {form.factory.diet[0]
              ? form.factory.diet[0].diets.map((item, index) => (
                  <tr key={index + item.diet}>
                    <td>{item.name || "Nova Dieta"}</td>
                    <td>{convertNumberToString(item.elg, 2)}</td>
                    <td>{convertNumberToString(item.elm)}</td>
                    <td>{convertNumberToString(item.costFinalDiet)}</td>
                    <td>
                      <Button
                        bg="light"
                        border="orange"
                        color="orange"
                        size="sm"
                        onClick={() => setIndexDiet(index)}
                      >
                        Editar Dieta &nbsp;
                        <Icons type="edit" color="#FC8535" size={15} />
                      </Button>{" "}
                    </td>
                  </tr>
                ))
              : null}
            <tr>
              <td colSpan={4}></td>
              <td>
                <Button
                  bg="default"
                  border="default"
                  color="white"
                  size="sm"
                  onClick={newDietHandler}
                >
                  Nova Dieta&nbsp;
                  <Icons type="plux" color="#ffffff" size={15} />
                </Button>
              </td>
            </tr>
          </Body>
        </TableContent>
      </Col>
    </Row>
  ) : (
    <Diet
      form={form}
      setForm={setForm}
      indexDiet={indexDiet}
      setIndexDiet={setIndexDiet}
    />
  );
}
