import React from "react";
import { Input } from "../../../../../../../components/Input";
import { Alert, Col, Row } from "../../../../../../../styles";

export function ChecklistData({ form, setForm, step }) {
  return (
    <>
      <Row>
        <Col>
          <Input
            required
            item={form}
            setItem={setForm}
            params="scenery"
            type="autocomplete"
            label="Cenário"
            paramsGet={["farmName"]}
            paramsLabel={["farmName", "state", "city"]}
            select="farmName state city"
            model="scenery"
            placeholder="Digite o nome da fazenda"
          />
        </Col>
        <Col>
          <Input
            item={form}
            setItem={setForm}
            params="date"
            type="inputMask"
            label="Data da Visita"
            mask="99/99/9999"
            required
          />
        </Col>
        <Col>
          <Input
            item={form}
            setItem={setForm}
            params="checklistType"
            type="select"
            label="Tipo de Checklist"
            placeholder="Selecione tipo de checklist"
            options={[{ value: "Pasto" }, { value: "Confinamento" }]}
            required
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <label>Usuário</label>
          <strong>{form.user.name}</strong>
        </Col>
        <Col>
          {form.score === "incompleto" ? (
            <Alert
              color={form.score
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")}
            >
              {form.score}
            </Alert>
          ) : (
            <Input
              type="select"
              placeholder={`Selecione a nota da avaliação`}
              label="Avaliação"
              item={form}
              setItem={setForm}
              params={`score`}
              options={[
                {
                  value: "Adequado",
                  label: "Adequado",
                },

                {
                  value: "Atenção",
                  label: "Atenção",
                },
                {
                  value: "Corrigir",
                  label: "Corrigir",
                },
              ]}
            />
          )}
        </Col>
      </Row>
    </>
  );
}
