import React, { useState, useEffect, useContext } from "react";
import { SubHeader } from "../../../../../../components/SubHeader";
import { api } from "../../../../../../services/api";
import Swal from "sweetalert2";
import UserProfileContext from "../../../../../../contexts/profile";
import { states } from "../../../../../../utils/globalData";
import { hasPermission } from "../../../../../../utils/globalFunctions";
import { Input } from "../../../../../../components/Input";
import { ViewLoader } from "../../../../../../components/ViewLoader";
import { Icons } from "../../../../../../components/Icons";
import {
  Block,
  BlockBody,
  BlockHeader,
  Col,
  Row,
  Separator,
} from "../../../../../../styles";
import { Button } from "../../../../../../components/Button";

export function CreateEditScenery(props) {
  const profileContext = useContext(UserProfileContext);

  const [scenery, setScenery] = useState({
    customer: {
      corporateName: "",
      code: "",
    },
    name: "",
    phoneNumber: "",
    state: "",
    city: "",
    cnpj: "",
    email: "",
    responsible: [],
    status: true,
    _id: "",
  });

  const [getCustomer, setGetCustomer] = useState(true);

  const [loading, setLoading] = useState(true);

  const addReponsable = () => {
    const sceneryCopy = { ...scenery };
    sceneryCopy.responsible.push(null);
    setScenery(sceneryCopy);
  };

  const removeReponsable = (index) => {
    Swal.fire({
      title: "Deseja remover item?",
      text: "Está ação só terá efeito após salvar o cenário!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0451e8",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, remover item!",
      preConfirm: () => {
        const sceneryCopy = { ...scenery };
        sceneryCopy.responsible.splice(index, 1);
        setScenery(sceneryCopy);
      },
    });
  };

  const saveCustomer = async (e) => {
    e.preventDefault();
    const requestCustomer = { ...scenery };
    requestCustomer.responsible = requestCustomer.responsible.map((item) =>
      item ? item.user._id : null
    );
    if (!props.match.params.id) {
      const response = await Swal.fire({
        title: "Criar Cenário",
        text: "Deseja confirmar criação da Cenário",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0451e8",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, Criar Cenário!",
        showLoaderOnConfirm: true,
        preConfirm: async () =>
          await api.post("scenery", requestCustomer).catch((err) => ({
            err: true,
            data: { message: err.response.data.message },
          })),
      });
      if (response.value) {
        Swal.fire(
          "Criar Cenário",
          response.value.data.message,
          response.value.err ? "error" : "success"
        );
        if (!response.value.err) {
          props.history.replace(
            "/basicregistration/scenery/edit/" + response.value.data.id
          );
          setGetCustomer(true);
        }
      }
    } else {
      const response = await Swal.fire({
        title: "Editar Cenário",
        text: "Deseja confirmar edição do Cenário",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0451e8",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, Editar Cenário!",
        showLoaderOnConfirm: true,
        preConfirm: async () =>
          await api
            .put("scenery/" + props.match.params.id, requestCustomer)
            .catch((err) => ({
              err: true,
              data: { message: err.response.data.message },
            })),
      });

      if (response.value) {
        Swal.fire(
          "Editar Cenário",
          response.value.data.message,
          response.value.err ? "error" : "success"
        );
        setGetCustomer(true);
      }
    }
  };

  useEffect(() => {
    const loadCustomer = async () => {
      if (props.match.params.id) {
        setLoading(true);
        const response = await api.get(`scenery/${props.match.params.id}`);
        if (!response.data) {
          Swal.fire(
            "Editar Cenário",
            "Não foi possível buscar o Cenário",
            "error"
          );
        }

        setScenery(response.data);
      }
      setLoading(false);
    };
    if (getCustomer) {
      setGetCustomer(false);
      loadCustomer();
    }
  }, [props.match, getCustomer, scenery]);

  return (
    <>
      <ViewLoader isLoading={loading} />
      <SubHeader
        {...props}
        title="Cenário"
        breadcrumbs={[
          { label: "Cadastro Base" },
          {
            label: "Cenários",
            to: "/basicregistration/scenery/1/30/index/{}",
          },
          {
            label: hasPermission(profileContext, "scenery", "edit")
              ? "Cenário"
              : props.match.params.id
              ? "Editar Cenário"
              : "Novo Cenário",
          },
        ]}
        icon={<Icons type="scenery" size="24" color="#fff" />}
      />
      <Block className="animate__animated animate__fadeInUp">
        <BlockHeader>
          Preencha os dados do Cenário
          <Input
            type="switch"
            item={scenery}
            setItem={setScenery}
            params="status"
            label="status"
            labelPlacement="start"
          />
        </BlockHeader>
        <BlockBody>
          <form onSubmit={(e) => saveCustomer(e)}>
            <Row>
              <Col>
                <Input
                  item={scenery}
                  setItem={setScenery}
                  params="customer"
                  type="autocomplete"
                  label="Cliente"
                  paramsGet={["code", "corporateName"]}
                  paramsLabel={["code", "corporateName"]}
                  select="code corporateName"
                  model="customer"
                  placeholder="Digite o Código ou a Razão social do Cliente"
                />
              </Col>
              <Col>
                <Input
                  type="input"
                  item={scenery}
                  setItem={setScenery}
                  params="farmName"
                  label="Nome da Fazenda"
                  placeholder="Nome da Fazenda"
                  capitalize={true}
                  required={true}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Input
                  type="input"
                  item={scenery}
                  setItem={setScenery}
                  params="owner"
                  label="Proprietário"
                  placeholder="Nome do proprietário"
                  capitalize={true}
                  required={true}
                />
              </Col>
              <Col>
                <Input
                  type="input"
                  item={scenery}
                  setItem={setScenery}
                  params="numberOfAnimals"
                  label="Número de Animais"
                  placeholder="Número de Animais"
                  inputType="number"
                  capitalize={true}
                  required={true}
                />
              </Col>

              <Col>
                <Input
                  type="select"
                  label="Estado"
                  placeholder="Selecione o Estado"
                  item={scenery}
                  setItem={setScenery}
                  params="state"
                  options={states.estados.map((estado, index) => ({
                    value: estado.nome,
                    label: estado.nome,
                  }))}
                />
              </Col>
              <Col>
                <Input
                  type="input"
                  params="city"
                  item={scenery}
                  setItem={setScenery}
                  label="Cidade"
                  placeholder="Digite a Cidade"
                />
              </Col>
            </Row>
            <Separator />
            {scenery.responsible.map((item, index) => (
              <Row key={index}>
                <Col key={"user"} size={3}>
                  <Input
                    item={scenery}
                    setItem={setScenery}
                    params={`responsible.${index}`}
                    type="autocomplete"
                    label={"Responsável"}
                    paramsGet={["code", "name"]}
                    paramsLabel={["code", "name"]}
                    select={"code name"}
                    model={"user"}
                    placeholder={"Digite o nome do responsável"}
                  />
                </Col>
                <Col size={1} style={{ paddingTop: "33px" }}>
                  <Icons
                    type="trash"
                    color="#F64E60"
                    size={24}
                    onClick={() => removeReponsable(index)}
                  />
                </Col>
              </Row>
            ))}

            <Row>
              <Col>
                <Button
                  type="button"
                  size="sm"
                  onClick={addReponsable}
                  color="white"
                  bg="orange"
                  border="orange"
                >
                  <Icons type="plus" color="#fff" size={15} /> Add Responsável
                </Button>
              </Col>
            </Row>

            <Separator />
            {hasPermission(profileContext, "scenery", "edit") ? (
              <Row>
                <Col style={{ alignItems: "center" }}>
                  <Button
                    type="submit"
                    bg="orange"
                    border="orange"
                    color="white"
                    disabled={!hasPermission(profileContext, "user", "edit")}
                  >
                    Salvar
                  </Button>
                </Col>
              </Row>
            ) : null}
          </form>
        </BlockBody>
      </Block>
    </>
  );
}
