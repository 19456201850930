import { Icons } from "../../../../../../components/Icons";
import { SubHeader } from "../../../../../../components/SubHeader";
import { Table } from "../../../../../../components/Table";

export function ListCustomers(props) {
  const tableParams = [
    {
      key: "code",
      label: "Código Cliente",
      width: 250,
      filter: "text",
      placeholder: "Digite o Código",
      mobile: {
        type: "title",
      },
    },
    {
      key: "corporateName",
      label: "Razão Social",
      filter: "text",
      placeholder: "Digite a Razão Social",
      mobile: {
        type: "title",
      },
    },

    {
      key: "status",
      type: "status",
      label: "Status",
      width: 150,
      filter: "status",
      mobile: {
        type: "status",
      },
    },
  ];

  return (
    <>
      <SubHeader
        {...props}
        title="Clientes"
        toolbar={["exportPDF"]}
        route="customer"
        newLabel="Novo Cliente"
        newLink="/basicregistration/customer/create"
        breadcrumbs={[
          { label: "Cadastro Base" },
          {
            label: "Clientes",
          },
        ]}
        icon={<Icons type="customer" size="24" color="#fff" />}
      />
      <Table
        cols={tableParams}
        editLink={"/basicregistration/customer/edit/"}
        emptyText={"Nenhum usuário localizado!"}
        route={"customer"}
        {...props}
      />
    </>
  );
}
