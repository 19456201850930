export const menuconfig = [
  {
    to: "/",
    label: "Home",
    icon: "flaticon-home-2",
    module: "home",
    description: "Dashboard",
  },
  {
    label: "Cadastro Base",
    icon: "flaticon-interface-3",
    module: "basicregistration",
    description: "Cadastro de Dados",
    subMenu: [
      {
        label: "Usuários",
        module: "user",
        to: "/basicregistration/user/1/30/index/{}",
      },
      {
        label: "Perfís",
        module: "profile",
        to: "/basicregistration/profile/1/30/index/{}",
      },
      {
        label: "Clientes",
        module: "customer",
        to: "/basicregistration/customer/1/30/index/{}",
      },
      {
        label: "Cenários",
        module: "scenery",
        to: "/basicregistration/scenery/1/30/index/{}",
      },
      {
        label: "Checklists",
        module: "checklist",
        to: "/basicregistration/checklist/1/30/index/{}",
      },
    ],
  },
];
