import { Container, Content, Copyright } from "./style";

export function Footer() {
  return (
    <Container className="animate__animated animate__fadeInUp">
      <div>
        <Content>
          <img
            alt="Logo"
            src="https://zenbytes-public.sfo2.digitaloceanspaces.com/mcassab/logo-MCassab-hz-BRANCA2.svg"
          />
          <Copyright>
            © {new Date().getFullYear()} Desenvolvido por Zenbytes
          </Copyright>
        </Content>
      </div>
    </Container>
  );
}
