import React from "react";
import { Button } from "../../../../../../../../../components/Button";
import { Input } from "../../../../../../../../../components/Input";
import { ButtonContent } from "../../../../../../../../../components/Table/style";
import { Col, Row, Separator } from "../../../../../../../../../styles";
import { Title } from "../../../style";

export function Food({ form, setForm, indexFood, setIndexFood }) {
  return (
    <>
      <Title>Editar Curral</Title>
      <Row>
        <Col>
          <Input
            type="input"
            label="Nome do Alimento"
            item={form}
            setItem={setForm}
            params={`factory.diet.${indexFood}.food`}
          />
        </Col>
        <Col>
          <Input
            type="input"
            label="Estoque (Ton.)"
            item={form}
            setItem={setForm}
            params={`factory.diet.${indexFood}.stock`}
          />
        </Col>
      </Row>
      <ButtonContent>
        <Button
          bg="default"
          border="default"
          color="white"
          onClick={() => setIndexFood(false)}
          style={{ marginRight: 20 }}
        >
          Salvar Dieta
        </Button>
      </ButtonContent>
      <Separator />
    </>
  );
}
