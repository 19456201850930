import { format } from "date-fns";
import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { api } from "../../services/api";
import { Button } from "../Button";
import saveAs from "file-saver";
import ReactLoading from "react-loading";
import {
  Breadcrumbs,
  Container,
  Main,
  Separator,
  Title,
  Toolbar,
} from "./style";

export function SubHeader({
  title,
  breadcrumbs,
  icon,
  toolbar,
  history,
  match,
  route,
  newLabel,
  newLink,
  subHeaderData,
  exportReport,
}) {
  const [loadExport, setLoadExport] = useState(false);

  const exportPDF = useCallback(async () => {
    setLoadExport(true);
    const response = await api
      .get(`export/pdf/${route}`, {
        params: { ...match.params },
        responseType: "arraybuffer",
        headers: {
          Accept: "application/pdf",
        },
      })
      .catch((err) => err);
    setLoadExport(false);
    if (!response.data) {
      return Swal.fire("Erro", response.message, "error");
    }
    const blob = new Blob([response.data], {
      type: "application/pdf",
    });
    saveAs(
      blob,
      `Relatório-de-${title}-${format(new Date(), "yyyy-MM-dd-hh:mm:ss")}.pdf`
    );
    document.getElementById("root").click();
  }, [title, match, route]);

  const toobarConfig = {
    exportPDF: (
      <span key="pdf" onClick={exportPDF}>
        Exportar PDF
      </span>
    ),
    exportReport: (
      <span key="pdf" onClick={exportReport}>
        Exportar Relatório
      </span>
    ),
  };

  const onClear = () => {
    history.push(
      match.path
        .replace(":page", "1")
        .replace(":limit", match.params.limit)
        .replace(":sort", match.params.sort)
        .replace(":query", "{}")
        .replace(":id", match.params.id)
    );
  };

  return (
    <Container>
      <Main className="animate__animated animate__fadeInLeft">
        <Title>{title}</Title>
        <Breadcrumbs>
          {icon}
          {breadcrumbs.map(({ label, to }, index) => (
            <React.Fragment key={label + index}>
              <Separator />
              {to ? (
                <Link key={label + index} to={to}>
                  {label}
                </Link>
              ) : (
                <span key={label + index}>{label}</span>
              )}
            </React.Fragment>
          ))}
          {match?.params?.query && match?.params?.query !== "{}" ? (
            <>
              <Separator />
              <Button
                bg="warning"
                border="warning"
                style={{ height: "20px", fontSize: "10px" }}
                color="white"
                onClick={subHeaderData?.onClear || onClear}
              >
                Limpar Filtro
              </Button>
            </>
          ) : null}
        </Breadcrumbs>
      </Main>
      <Toolbar className="animate__animated animate__fadeInRight">
        {toolbar && (
          <Button
            type="button"
            bg="white"
            color="black"
            border="opacity"
            dropdown={toolbar.map((key, index) => (
              <React.Fragment key={key + index}>
                {toobarConfig[key]}
              </React.Fragment>
            ))}
            loading={loadExport.toString()}
          >
            {loadExport
              ? [
                  "Aguarde",
                  <>&nbsp;</>,
                  <ReactLoading
                    type="spin"
                    color="#FC8535"
                    height={15}
                    width={15}
                  />,
                ]
              : "Opções"}
          </Button>
        )}
        {newLink && (
          <Button
            type="button"
            bg="primary"
            color="white"
            border="primary"
            to={newLink}
          >
            {newLabel}
          </Button>
        )}
        <Button
          bg="orange"
          color="white"
          border="orange"
          onClick={() => history.goBack()}
        >
          Voltar
        </Button>
      </Toolbar>
    </Container>
  );
}
