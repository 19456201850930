import React, { useState } from "react";
import { Title } from "../style";
import { Tab, Tabs } from "@material-ui/core";
import { Evaluation } from "../Evaluation";

export function WaterQuality({ form, setForm, step }) {
  const [tab, setTab] = useState(0);

  const evaluationConfig = [
    {
      label: "Qualidade de Água",
      key: "waterQuality",
    },
    {
      label: "Área de Bebedouro/Animal",
      key: "troughArea",
    },
    {
      label: "Acesso Adequedo",
      key: "adequateAccess",
    },
    {
      label: "Cor",
      key: "color",
    },
    {
      label: "Cheiro",
      key: "smell",
    },
    {
      label: "Avaliação Geral Qualidade de Água",
    },
  ];

  return (
    <>
      <Title>Avaliação Qualidade de Água</Title>
      <Tabs
        value={tab}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="on"
        onChange={(e, value) => {
          setTab(value);
        }}
      >
        {evaluationConfig.map(({ label }) => (
          <Tab label={label} />
        ))}
      </Tabs>
      <Evaluation
        {...{ form, setForm, tab, evaluationConfig, step }}
        id={form._id}
      />
    </>
  );
}
