import { useState } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { Forgot } from "./bovinos/Auth/Forgot";
import { Login } from "./bovinos/Auth/Login";
import { NewPassword } from "./bovinos/Auth/NewPassword";
import { App as Application } from "./bovinos/App";
import Basf from "./contexts/basf";
import { GlobalStyle } from "./styles/global";
import { PrivacityPolicy } from "./bovinos/App/Views/PrivacityPolicy";
import { TermsConditions } from "./bovinos/App/Views/TermsConditions";

export function App() {
  const [basf, setBasf] = useState(false);

  return (
    <Basf.Provider value={{ basf, setBasf }}>
      <GlobalStyle basf={false} />
      <Router>
        <Switch>
          <Route
            path="/privacity-policy"
            render={(props) => <PrivacityPolicy />}
          />
          <Route
            path="/terms-conditions"
            render={(props) => <TermsConditions />}
          />
          <Route
            path="/new-password/:token"
            render={(props) => <NewPassword {...props} />}
          />
          <Route path="/forgot" render={(props) => <Forgot {...props} />} />
          <Route path="/login" render={(props) => <Login {...props} />} />
          <Route path="/" render={(props) => <Application {...props} />} />
        </Switch>
      </Router>
    </Basf.Provider>
  );
}
