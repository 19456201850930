import React, { useState } from "react";
import { Button } from "../../../../../../../../components/Button";
import { Icons } from "../../../../../../../../components/Icons";
import {
  Body,
  Header,
  TableContent,
} from "../../../../../../../../components/Table/style";
import { Col, Row } from "../../../../../../../../styles";
import { Title } from "../../style";
import { Food } from "./Food";

export function Foods({ form, setForm }) {
  const [indexFood, setIndexFood] = useState(false);
  const newFoodHandler = () => {
    const newState = JSON.parse(JSON.stringify(form));
    newState.factory.diet.push({
      food: "",
      stock: "",
      beated: "",
      done: "",
      diets: [],
    });
    setForm(newState);
  };
  return indexFood === false ? (
    <Row>
      <Col>
        <Title>Alimentos</Title>
        <TableContent>
          <Header>
            <tr>
              <th>Nome do Alimento</th>
              <th>Estoque (Ton.)</th>
              <th style={{ width: 180 }}>Editar Alimento</th>
            </tr>
          </Header>
          <Body>
            {form.factory?.diet?.map((item, index) => (
              <tr key={index + item.food}>
                <td>{item.food || "Novo Alimento"}</td>
                <td>{item.stock}</td>
                <td>
                  <Button
                    bg="light"
                    border="orange"
                    color="orange"
                    size="sm"
                    onClick={() => setIndexFood(index)}
                  >
                    Editar Alimento &nbsp;
                    <Icons type="edit" color="#FC8535" size={15} />
                  </Button>{" "}
                </td>
              </tr>
            ))}
            <tr>
              <td colSpan={2}></td>
              <td>
                <Button
                  bg="default"
                  border="default"
                  color="white"
                  size="sm"
                  onClick={newFoodHandler}
                >
                  Novo Alimento&nbsp;
                  <Icons type="plux" color="#ffffff" size={15} />
                </Button>
              </td>
            </tr>
          </Body>
        </TableContent>
      </Col>
    </Row>
  ) : (
    <Food
      form={form}
      setForm={setForm}
      indexFood={indexFood}
      setIndexFood={setIndexFood}
    />
  );
}
