import styled from "styled-components";

export const ImagesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const ImageContent = styled.div`
  position: relative;
`;

export const ImageLink = styled.a.attrs({
  rel: "noreferrer",
  target: "_blank",
})``;

export const ImageItem = styled.div`
  background-image: url(${({ img }) => img});
  background-size: cover;
  background-position: center;
  height: 135px;
  width: 135px;
  margin-right: 10px;
  border-radius: 6px;
`;

export const RemoveButtom = styled.div`
  position: absolute;
  top: 0;
  right: 10px;
  z-index: 2;
`;
