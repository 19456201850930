import React from "react";
import { Button } from "../../../../../../../../../components/Button";
import { Input } from "../../../../../../../../../components/Input";
import {
  Body,
  ButtonContent,
  Header,
  TableContent,
} from "../../../../../../../../../components/Table/style";
import { Col, Row, Separator } from "../../../../../../../../../styles";
import { convertNumberToString } from "../../../../../../../../../utils/globalFunctions";
import { Title } from "../../../style";

export function Diet({ form, setForm, indexDiet, setIndexDiet }) {
  return (
    <>
      <Title>Editar Dieta</Title>
      <Row>
        <Col>
          <Input
            type="input"
            label="Nome da Dieta"
            item={form}
            setItem={setForm}
            params={`factory.diet.${indexDiet}.diet`}
          />
        </Col>
        <Col>
          <Input
            type="input"
            label="Elg mcal/kg"
            item={form}
            setItem={setForm}
            params={`factory.diet.${0}.diets.${indexDiet}.elg`}
          />
        </Col>
        <Col>
          <Input
            type="input"
            label="Elm mca/kg"
            item={form}
            setItem={setForm}
            params={`factory.diet.${0}.diets.${indexDiet}.elm`}
          />{" "}
        </Col>
        <Col>
          <Input
            type="input"
            label="Custo kg/MS da Dieta"
            item={form}
            setItem={setForm}
            params={`factory.diet.${0}.diets.${indexDiet}.costFinalDiet`}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TableContent>
            <Header>
              <tr>
                <th>Alimento</th>
                <th>Estoque</th>
                <th style={{ width: 180 }}>Porcentagem (%)</th>
              </tr>
            </Header>
            <Body>
              {form.factory.diet.map((item, index) => (
                <tr>
                  <td>{item.food}</td>
                  <td>{item.stock}</td>
                  <td>
                    <Input
                      key={item.food + index}
                      type="inputOnly"
                      placeholder={`(%)`}
                      item={form}
                      setItem={setForm}
                      params={`factory.diet.${index}.diets.${indexDiet}.value`}
                    />
                  </td>
                </tr>
              ))}

              <tr>
                <td colSpan={2}></td>
                <td>
                  Total:{" "}
                  {convertNumberToString(
                    form.factory.diet
                      .map(
                        (item) =>
                          +item.diets[indexDiet].value
                            .toString()
                            .replace(",", ".")
                      )
                      .reduce((a, b) => a + b, 0),
                    2
                  )}
                  %
                </td>
              </tr>
            </Body>
          </TableContent>
        </Col>
      </Row>
      <ButtonContent>
        <Button
          bg="default"
          border="default"
          color="white"
          onClick={() => setIndexDiet(false)}
          style={{ marginRight: 20 }}
        >
          Salvar Dieta
        </Button>
      </ButtonContent>
      <Separator />
    </>
  );
}
