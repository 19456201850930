import React, { useState } from "react";
import { Title } from "../style";

import { Corrals } from "./Corrals";
import { Tab, Tabs } from "@material-ui/core";
import { Evaluation } from "../Evaluation";

export function Office({ form, setForm, step }) {
  const [tab, setTab] = useState(0);

  const evaluationConfig = [
    {
      key: "daysInConfinement",
      label: "Dias em confinamento X Dieta",
    },
    {
      key: "pvp",
      label: "Peso Vivo Projetado (PVP), kg",
    },
    {
      key: "pv",
      label: "CMS % Peso Vivo (PV)",
    },
    {
      label: "Avaliação Geral Escritório",
    },
  ];

  return (
    <>
      <Corrals {...{ form, setForm }} />
      <Title>Avaliação Escritório</Title>
      <Tabs
        value={tab}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="on"
        onChange={(e, value) => {
          setTab(value);
        }}
      >
        {evaluationConfig.map(({ label }) => (
          <Tab label={label} />
        ))}
      </Tabs>
      <Evaluation
        {...{ form, setForm, tab, evaluationConfig, step }}
        id={form._id}
      />
    </>
  );
}
