import { Tab, Tabs } from "@material-ui/core";
import React, { useState, useContext, useEffect } from "react";
import { Button } from "../../../../../../components/Button";
import { Icons } from "../../../../../../components/Icons";
import { Modal } from "../../../../../../components/Modal";
import { ChecklistData } from "./ChecklistData";
import { Container } from "./style";
import ProfileContext from "../../../../../../contexts/profile";
import {
  convertDateToString,
  convertStringToDate,
  getValue,
  setValue,
} from "../../../../../../utils/globalFunctions";
import { api } from "../../../../../../services/api";
import { Office } from "./Office";
import { Factory } from "./Factory";
import { TreatmentHandling } from "./TreatmentHandling";
import { GrainProcessing } from "./GrainProcessing";
import { MixingQuality } from "./MixingQuality";
import { BulkyNDFfe } from "./BulkyNDFfe";
import { StoolScore } from "./StoolScore";
import { WaterQuality } from "./WaterQuality";
import { Animals } from "./Animals";
import Swal from "sweetalert2";

export function CreateEditChecklist({ id, show, setShow }) {
  const profile = useContext(ProfileContext);

  const [loading, setLoading] = useState(false);

  const [tab, setTab] = useState(0);

  const [getData, setGetData] = useState(true);

  const [isChecked, setIsChecked] = useState({
    office: {
      checked: false,
      daysInConfinement: false,
      pvp: false,
      pv: false,
    },
    factory: {
      checked: false,
      stock: false,
      organization: false,
      storage: false,
      pestsFungiImpurities: false,
      dietRegister: false,
      orderOfInclusion: false,
      predictedVsPerformed: false,
      mixingTime: false,
    },
    treatmentHandling: {
      checked: false,
      correctDiet: false,
      correctAmount: false,
      correctTime: false,
      inTheRightWay: false,
      dietSegregation: false,
    },
    grainProcessing: {
      checked: false,
      averageSizeAndParticlesDistribution: false,
    },
    mixingQuality: {
      checked: false,
      wagonStart19mm: false,
      wagonStart8mm: false,
      wagonStart4mm: false,
      wagonStartDepth: false,
      wagonEnd19mm: false,
      wagonEnd8mm: false,
      wagonEnd4mm: false,
      wagonEndDepth: false,
      wagonStart: false,
      wagonEnd: false,
    },
    bulkyNDFfe: {
      checked: false,
      d19mm: false,
      d8mm: false,
      d4mm: false,
      depth: false,
      fdn: false,
      forageParticleEffectiveness: false,
    },
    stoolScore: {
      checked: false,
      s1: false,
      s2: false,
      s3: false,
      s4: false,
      s5: false,
    },
    waterQuality: {
      checked: false,
      waterQuality: false,
      troughArea: false,
      adequateAccess: false,
      color: false,
      smell: false,
    },
    animals: {
      score: false,
      ECC: false,
      nasalDischarge: false,
      ruffledHair: false,
      dullFur: false,
      duringTheDeal: false,
      animalsInBarnyardBackground: false,
      ruminating: false,
      idleness: false,
      hullProblems: false,
      corralFloor: false,
      mud: false,
      m2ox: false,
      troughAreaCmBull: false,
      tractOfTreatment: false,
    },
  });

  const [form, setForm] = useState({
    scenery: "",
    date: "",
    checklistType: "",
    office: {
      corrals: [],
      pastures: [],
      evaluation: {
        daysInConfinement: {
          score: "",
          justification: "",
          action: "",
          images: [],
        },
        pvp: {
          score: "",
          justification: "",
          action: "",
          images: [],
        },
        /*  diet: {
          score: "",
          justification: "",
          action: "",
          images: [],
        }, */
        /*  cms: {
          score: "",
          justification: "",
          action: "",
          images: [],
        }, */
        pv: {
          score: "",
          justification: "",
          action: "",
          images: [],
        },
      },
      score: "",
    },
    factory: {
      diet: [],
      evaluation: {
        stock: {
          score: "",
          justification: "",
          action: "",
          images: [],
        },
        organization: {
          score: "",
          justification: "",
          action: "",
          images: [],
        },
        storage: {
          score: "",
          justification: "",
          action: "",
          images: [],
        },
        pestsFungiImpurities: {
          score: "",
          justification: "",
          action: "",
          images: [],
        },
        dietRegister: { score: "", justification: "", action: "", images: [] },
        orderOfInclusion: {
          score: "",
          justification: "",
          action: "",
          images: [],
        },
        predictedVsPerformed: {
          score: "",
          justification: "",
          action: "",
          images: [],
        },
        mixingTime: { score: "", justification: "", action: "", images: [] },
        supplementFormulationRegistration: {
          score: "",
          justification: "",
          action: "",
          images: [],
        },
        mixerGrounding: {
          score: "",
          justification: "",
          action: "",
          images: [],
        },
        wearOfHelicoidsPast: {
          score: "",
          justification: "",
          action: "",
          images: [],
        },
        presenceOfMagnet: {
          score: "",
          justification: "",
          action: "",
          images: [],
        },
        capacityXHittingWeight: {
          score: "",
          justification: "",
          action: "",
          images: [],
        },
      },
      score: "",
    },
    treatmentHandling: {
      evaluation: {
        correctDiet: {
          score: "",
          justification: "",
          action: "",
          images: [],
        },
        /*  correctCorral: {
          score: "",
          justification: "",
          action: "",
          images: [],
        }, */
        correctAmount: {
          score: "",
          justification: "",
          action: "",
          images: [],
        },
        correctTime: {
          score: "",
          justification: "",
          action: "",
          images: [],
        },
        inTheRightWay: {
          score: "",
          justification: "",
          action: "",
          images: [],
        },
        dietSegregation: {
          score: "",
          justification: "",
          action: "",
          images: [],
        },
      },
      score: "",
    },
    grainProcessing: {
      value6: "",
      value3: "",
      value2: "",
      value1: "",
      valueDepth: "",
      evaluation: {
        averageSizeAndParticlesDistribution: {
          score: "",
          justification: "",
          action: "",
          images: [],
        },
      },
      score: "",
    },
    mixingQuality: {
      wagonStart: {
        mm19: {
          start: "",
          mid: "",
          end: "",
        },
        mm8: {
          start: "",
          mid: "",
          end: "",
        },
        mm4: {
          start: "",
          mid: "",
          end: "",
        },
        depth: {
          start: "",
          mid: "",
          end: "",
        },
      },
      wagonEnd: {
        mm19: {
          start: "",
          mid: "",
          end: "",
        },
        mm8: {
          start: "",
          mid: "",
          end: "",
        },
        mm4: {
          start: "",
          mid: "",
          end: "",
        },
        depth: {
          start: "",
          mid: "",
          end: "",
        },
      },
      evaluation: {
        wagonStart: {
          score: "",
          justification: "",
          action: "",
          images: [],
        },
        wagonEnd: {
          score: "",
          justification: "",
          action: "",
          images: [],
        },
      },
      score: "",
    },
    bulkyNDFfe: {
      d19mm: "",
      d8mm: "",
      d4mm: "",
      depth: "",
      fdn: "",
      evaluation: {
        forageParticleEffectiveness: {
          score: "",
          justification: "",
          action: "",
          images: [],
        },
      },
      score: "",
    },
    stoolScore: {
      evaluation: {
        s1: {
          distribution: "",
          relevance: "",
          action: "",
          images: [],
          score: "",
        },
        s2: {
          distribution: "",
          relevance: "",
          action: "",
          images: [],
          score: "",
        },
        s3: {
          distribution: "",
          relevance: "",
          action: "",
          images: [],
          score: "",
        },
        s4: {
          distribution: "",
          relevance: "",
          action: "",
          images: [],
          score: "",
        },
        s5: {
          distribution: "",
          relevance: "",
          action: "",
          images: [],
          score: "",
        },
      },
      score: "",
    },
    waterQuality: {
      evaluation: {
        waterQuality: {
          score: "",
          justification: "",
          action: "",
          images: [],
        },
        troughArea: {
          score: "",
          justification: "",
          action: "",
          images: [],
        },
        adequateAccess: {
          score: "",
          justification: "",
          action: "",
          images: [],
        },
        color: {
          score: "",
          justification: "",
          action: "",
          images: [],
        },
        smell: {
          score: "",
          justification: "",
          action: "",
          images: [],
        },
      },
      score: "",
    },
    animals: {
      evaluation: {
        ECC: { score: "", justification: "", action: "", images: [] },
        nasalDischarge: {
          score: "",
          justification: "",
          action: "",
          images: [],
        },
        ruffledHair: { score: "", justification: "", action: "", images: [] },
        dullFur: { score: "", justification: "", action: "", images: [] },
        duringTheDeal: { score: "", justification: "", action: "", images: [] },
        animalsInBarnyardBackground: {
          score: "",
          justification: "",
          action: "",
          images: [],
        },
        ruminating: { score: "", justification: "", action: "", images: [] },
        idleness: { score: "", justification: "", action: "", images: [] },
        hullProblems: { score: "", justification: "", action: "", images: [] },
        corralFloor: { score: "", justification: "", action: "", images: [] },
        mud: { score: "", justification: "", action: "", images: [] },
        m2ox: { score: "", justification: "", action: "", images: [] },
        troughAreaCmBull: {
          score: "",
          justification: "",
          action: "",
          images: [],
        },
        tractOfTreatment: {
          score: "",
          justification: "",
          action: "",
          images: [],
        },
      },
      score: "",
    },
    pastureAndAnimals: {
      evaluation: {
        soilExposedToSunlightAndRain: {
          score: "",
          justification: "",
          action: "",
          images: [],
        },
        structureOfTheForageCanopy: {
          score: "",
          justification: "",
          action: "",
          images: [],
        },
        heightOfTheForageDossier: {
          score: "",
          justification: "",
          action: "",
          images: [],
        },
        heightOfEntryAndExitOfAnimals: {
          score: "",
          justification: "",
          action: "",
          images: [],
        },
        ECC: { score: "", justification: "", action: "", images: [] },
      },
      score: "",
    },
    trough: {
      evaluation: {
        structure: {
          score: "",
          justification: "",
          action: "",
          images: [],
        },
        area: {
          score: "",
          justification: "",
          action: "",
          images: [],
        },
        access: {
          score: "",
          justification: "",
          action: "",
          images: [],
        },
        aspectOfTheSupplement: {
          score: "",
          justification: "",
          action: "",
          images: [],
        },
        behavior: { score: "", justification: "", action: "", images: [] },
      },
      score: "",
    },
    user: profile._id,
    score: "",
  });

  const saveChecklist = async () => {
    try {
      if (!form.scenery || !form.date) {
        Swal.fire(
          "Editar Checklist",
          "Preencha os campos obrigatórios",
          "error"
        );

        return false;
      }
      const query = JSON.parse(JSON.stringify(form));
      query.date = convertStringToDate(query.date);

      let score = "Adequado";

      Object.keys(isChecked).forEach((key) => {
        if (query[key].score === "Incompleto" || !query[key].score) {
          score = "Incompleto";
        }
      });

      query.score = score;
      const response = await Swal.fire({
        title: "Editar Checklist",
        text: "Deseja confirmar edição do Checklist",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0451e8",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, Editar Checklist!",
        showLoaderOnConfirm: true,
        preConfirm: async () =>
          await api.put("checklist/" + form._id, form).catch((err) => ({
            err: true,
            data: { message: err.response.data.message },
          })),
      });
      if (response.value) {
        Swal.fire(
          "Editar Checklist",
          response.value.data.message,
          response.value.err ? "error" : "success"
        );
        setGetData(true);
      }
    } catch (e) {
      Swal.fire("Editar Checklist", "Erro ao salvar Checkslist", "error");
    }
  };

  useEffect(() => {
    if (id) {
      const isCheckedConfig = [
        {
          formValue: [
            "office.evaluation.daysInConfinement.score",
            "office.evaluation.pvp.score",
            //"office.evaluation.diet.score",
            //"office.evaluation.cms.score",
            "office.evaluation.pv.score",
          ],
          isCheckedValue: "office.checked",
        },
        {
          formValue: ["office.evaluation.daysInConfinement.score"],
          isCheckedValue: "office.daysInConfinement",
        },
        {
          formValue: ["office.evaluation.pvp.score"],
          isCheckedValue: "office.pvp",
        },
        /* {
          formValue: ["office.evaluation.diet.score"],
          isCheckedValue: "office.diet",
        }, */
        /*  {
          formValue: ["office.evaluation.cms.score"],
          isCheckedValue: "office.cms",
        }, */
        {
          formValue: ["office.evaluation.pv.score"],
          isCheckedValue: "office.pv",
        },
        {
          formValue: [
            "factory.evaluation.stock.score",
            "factory.evaluation.organization.score",
            "factory.evaluation.storage.score",
            "factory.evaluation.pestsFungiImpurities.score",
            "factory.evaluation.dietRegister.score",
            "factory.evaluation.orderOfInclusion.score",
            "factory.evaluation.predictedVsPerformed.score",
            "factory.evaluation.mixingTime.score",
          ],
          isCheckedValue: "factory.checked",
        },
        {
          formValue: ["factory.evaluation.stock.score"],
          isCheckedValue: "factory.stock",
        },
        {
          formValue: ["factory.evaluation.organization.score"],
          isCheckedValue: "factory.organization",
        },
        {
          formValue: ["factory.evaluation.storage.score"],
          isCheckedValue: "factory.storage",
        },
        {
          formValue: ["factory.evaluation.pestsFungiImpurities.score"],
          isCheckedValue: "factory.pestsFungiImpurities",
        },
        {
          formValue: ["factory.evaluation.dietRegister.score"],
          isCheckedValue: "factory.dietRegister",
        },
        {
          formValue: ["factory.evaluation.orderOfInclusion.score"],
          isCheckedValue: "factory.orderOfInclusion",
        },
        {
          formValue: ["factory.evaluation.predictedVsPerformed.score"],
          isCheckedValue: "factory.predictedVsPerformed",
        },
        {
          formValue: ["factory.evaluation.mixingTime.score"],
          isCheckedValue: "factory.mixingTime",
        },
        {
          formValue: [
            "treatmentHandling.evaluation.correctDiet.score",
            //"treatmentHandling.evaluation.correctCorral.score",
            "treatmentHandling.evaluation.correctAmount.score",
            "treatmentHandling.evaluation.correctTime.score",
            "treatmentHandling.evaluation.inTheRightWay.score",
            "treatmentHandling.evaluation.dietSegregation.score",
          ],
          isCheckedValue: "treatmentHandling.checked",
        },
        {
          formValue: ["treatmentHandling.evaluation.correctDiet.score"],
          isCheckedValue: "treatmentHandling.correctDiet",
        },
        /* {
          formValue: ["treatmentHandling.evaluation.correctCorral.score"],
          isCheckedValue: "treatmentHandling.correctCorral",
        }, */
        {
          formValue: ["treatmentHandling.evaluation.correctAmount.score"],
          isCheckedValue: "treatmentHandling.correctAmount",
        },
        {
          formValue: ["treatmentHandling.evaluation.correctTime.score"],
          isCheckedValue: "treatmentHandling.correctTime",
        },
        {
          formValue: ["treatmentHandling.evaluation.inTheRightWay.score"],
          isCheckedValue: "treatmentHandling.inTheRightWay",
        },
        {
          formValue: ["treatmentHandling.evaluation.dietSegregation.score"],
          isCheckedValue: "treatmentHandling.dietSegregation",
        },
        {
          formValue: [
            "grainProcessing.value6",
            "grainProcessing.value3",
            "grainProcessing.value2",
            "grainProcessing.value1",
            "grainProcessing.valueDepth",
            "grainProcessing.evaluation.averageSizeAndParticlesDistribution.score",
          ],
          isCheckedValue: "grainProcessing.checked",
        },
        {
          formValue: [
            "grainProcessing.evaluation.averageSizeAndParticlesDistribution.score",
          ],
          isCheckedValue: "grainProcessing.averageSizeAndParticlesDistribution",
        },
        {
          formValue: [
            "mixingQuality.wagonStart.mm19.start",
            "mixingQuality.wagonStart.mm19.mid",
            "mixingQuality.wagonStart.mm19.end",
            "mixingQuality.wagonStart.mm8.start",
            "mixingQuality.wagonStart.mm8.mid",
            "mixingQuality.wagonStart.mm8.end",
            "mixingQuality.wagonStart.mm4.start",
            "mixingQuality.wagonStart.mm4.mid",
            "mixingQuality.wagonStart.mm4.end",
            "mixingQuality.wagonStart.depth.start",
            "mixingQuality.wagonStart.depth.mid",
            "mixingQuality.wagonStart.depth.end",
            "mixingQuality.wagonEnd.mm19.start",
            "mixingQuality.wagonEnd.mm19.mid",
            "mixingQuality.wagonEnd.mm19.end",
            "mixingQuality.wagonEnd.mm8.start",
            "mixingQuality.wagonEnd.mm8.mid",
            "mixingQuality.wagonEnd.mm8.end",
            "mixingQuality.wagonEnd.mm4.start",
            "mixingQuality.wagonEnd.mm4.mid",
            "mixingQuality.wagonEnd.mm4.end",
            "mixingQuality.wagonEnd.depth.start",
            "mixingQuality.wagonEnd.depth.mid",
            "mixingQuality.wagonEnd.depth.end",
            "mixingQuality.evaluation.wagonStart.score",
            "mixingQuality.evaluation.wagonEnd.score",
          ],
          isCheckedValue: "mixingQuality.checked",
        },
        {
          formValue: [
            "mixingQuality.wagonStart.mm19.start",
            "mixingQuality.wagonStart.mm19.mid",
            "mixingQuality.wagonStart.mm19.end",
          ],
          isCheckedValue: "mixingQuality.wagonStart19mm",
        },
        {
          formValue: [
            "mixingQuality.wagonStart.mm8.start",
            "mixingQuality.wagonStart.mm8.mid",
            "mixingQuality.wagonStart.mm8.end",
          ],
          isCheckedValue: "mixingQuality.wagonStart8mm",
        },
        {
          formValue: [
            "mixingQuality.wagonStart.mm4.start",
            "mixingQuality.wagonStart.mm4.mid",
            "mixingQuality.wagonStart.mm4.end",
          ],
          isCheckedValue: "mixingQuality.wagonStart4mm",
        },
        {
          formValue: [
            "mixingQuality.wagonStart.depth.start",
            "mixingQuality.wagonStart.depth.mid",
            "mixingQuality.wagonStart.depth.end",
          ],
          isCheckedValue: "mixingQuality.wagonStartDepth",
        },
        {
          formValue: [
            "mixingQuality.wagonEnd.mm19.start",
            "mixingQuality.wagonEnd.mm19.mid",
            "mixingQuality.wagonEnd.mm19.end",
          ],
          isCheckedValue: "mixingQuality.wagonEnd19mm",
        },
        {
          formValue: [
            "mixingQuality.wagonEnd.mm8.start",
            "mixingQuality.wagonEnd.mm8.mid",
            "mixingQuality.wagonEnd.mm8.end",
          ],
          isCheckedValue: "mixingQuality.wagonEnd8mm",
        },
        {
          formValue: [
            "mixingQuality.wagonEnd.mm4.start",
            "mixingQuality.wagonEnd.mm4.mid",
            "mixingQuality.wagonEnd.mm4.end",
          ],
          isCheckedValue: "mixingQuality.wagonEnd4mm",
        },
        {
          formValue: [
            "mixingQuality.wagonEnd.depth.start",
            "mixingQuality.wagonEnd.depth.mid",
            "mixingQuality.wagonEnd.depth.end",
          ],
          isCheckedValue: "mixingQuality.wagonEndDepth",
        },
        {
          formValue: ["mixingQuality.evaluation.wagonStart.score"],
          isCheckedValue: "mixingQuality.wagonStart",
        },
        {
          formValue: ["mixingQuality.evaluation.wagonStart.score"],
          isCheckedValue: "mixingQuality.wagonStart",
        },
        {
          formValue: ["mixingQuality.evaluation.wagonEnd.score"],
          isCheckedValue: "mixingQuality.wagonEnd",
        },

        {
          formValue: [
            "bulkyNDFfe.d19mm",
            "bulkyNDFfe.d8mm",
            "bulkyNDFfe.d4mm",
            "bulkyNDFfe.depth",
            "bulkyNDFfe.fdn",
            "bulkyNDFfe.evaluation.forageParticleEffectiveness.score",
          ],
          isCheckedValue: "bulkyNDFfe.checked",
        },
        {
          formValue: [
            "bulkyNDFfe.evaluation.forageParticleEffectiveness.score",
          ],
          isCheckedValue: "bulkyNDFfe.forageParticleEffectiveness",
        },
        {
          formValue: [
            "stoolScore.evaluation.s1.distribution",
            "stoolScore.evaluation.s2.distribution",
            "stoolScore.evaluation.s3.distribution",
            "stoolScore.evaluation.s4.distribution",
            "stoolScore.evaluation.s5.distribution",
          ],
          isCheckedValue: "stoolScore.checked",
        },
        {
          formValue: ["stoolScore.evaluation.s1.distribution"],
          isCheckedValue: "stoolScore.s1",
        },
        {
          formValue: ["stoolScore.evaluation.s2.distribution"],
          isCheckedValue: "stoolScore.s2",
        },
        {
          formValue: ["stoolScore.evaluation.s3.distribution"],
          isCheckedValue: "stoolScore.s3",
        },
        {
          formValue: ["stoolScore.evaluation.s4.distribution"],
          isCheckedValue: "stoolScore.s4",
        },
        {
          formValue: ["stoolScore.evaluation.s5.distribution"],
          isCheckedValue: "stoolScore.s5",
        },

        {
          formValue: [
            "waterQuality.evaluation.waterQuality.score",
            "waterQuality.evaluation.troughArea.score",
            "waterQuality.evaluation.adequateAccess.score",
            "waterQuality.evaluation.color.score",
            "waterQuality.evaluation.smell.score",
          ],
          isCheckedValue: "waterQuality.checked",
        },
        {
          formValue: ["waterQuality.evaluation.waterQuality.score"],
          isCheckedValue: "waterQuality.waterQuality",
        },
        {
          formValue: ["waterQuality.evaluation.troughArea.score"],
          isCheckedValue: "waterQuality.troughArea",
        },
        {
          formValue: ["waterQuality.evaluation.adequateAccess.score"],
          isCheckedValue: "waterQuality.adequateAccess",
        },
        {
          formValue: ["waterQuality.evaluation.color.score"],
          isCheckedValue: "waterQuality.color",
        },
        {
          formValue: ["waterQuality.evaluation.smell.score"],
          isCheckedValue: "waterQuality.smell",
        },
        {
          formValue: [
            "animals.evaluation.ECC.score",
            "animals.evaluation.nasalDischarge.score",
            "animals.evaluation.ruffledHair.score",
            "animals.evaluation.dullFur.score",
            "animals.evaluation.duringTheDeal.score",
            "animals.evaluation.animalsInBarnyardBackground.score",
            "animals.evaluation.ruminating.score",
            "animals.evaluation.idleness.score",
            "animals.evaluation.hullProblems.score",
            "animals.evaluation.corralFloor.score",
            "animals.evaluation.mud.score",
            "animals.evaluation.m2ox.score",
            "animals.evaluation.troughAreaCmBull.score",
            "animals.evaluation.tractOfTreatment.score",
          ],
          isCheckedValue: "animals.checked",
        },
        {
          formValue: ["animals.evaluation.ECC.score"],
          isCheckedValue: "animals.ECC",
        },
        {
          formValue: ["animals.evaluation.nasalDischarge.score"],
          isCheckedValue: "animals.nasalDischarge",
        },
        {
          formValue: ["animals.evaluation.ruffledHair.score"],
          isCheckedValue: "animals.ruffledHair",
        },
        {
          formValue: ["animals.evaluation.dullFur.score"],
          isCheckedValue: "animals.dullFur",
        },
        {
          formValue: ["animals.evaluation.duringTheDeal.score"],
          isCheckedValue: "animals.duringTheDeal",
        },
        {
          formValue: ["animals.evaluation.animalsInBarnyardBackground.score"],
          isCheckedValue: "animals.animalsInBarnyardBackground",
        },
        {
          formValue: ["animals.evaluation.ruminating.score"],
          isCheckedValue: "animals.ruminating",
        },
        {
          formValue: ["animals.evaluation.idleness.score"],
          isCheckedValue: "animals.idleness",
        },
        {
          formValue: ["animals.evaluation.hullProblems.score"],
          isCheckedValue: "animals.hullProblems",
        },
        {
          formValue: ["animals.evaluation.corralFloor.score"],
          isCheckedValue: "animals.corralFloor",
        },
        {
          formValue: ["animals.evaluation.m2ox.score"],
          isCheckedValue: "animals.m2ox",
        },
        {
          formValue: ["animals.evaluation.mud.score"],
          isCheckedValue: "animals.mud",
        },
        {
          formValue: ["animals.evaluation.troughAreaCmBull.score"],
          isCheckedValue: "animals.troughAreaCmBull",
        },
        {
          formValue: ["animals.evaluation.tractOfTreatment.score"],
          isCheckedValue: "animals.tractOfTreatment",
        },
      ];
      setIsChecked((prevState) => {
        const newState = JSON.parse(JSON.stringify(prevState));
        isCheckedConfig.forEach(({ formValue, isCheckedValue }) => {
          let response = true;
          formValue.forEach((value) => {
            const splitValue = value.split(".");
            let indexArray = -1;
            splitValue.forEach((item, index) => {
              if (item === "index") {
                indexArray = index;
              }
            });
            if (indexArray > -1) {
              let valueArray = splitValue[0];
              for (let i = 1; i < indexArray; i++) {
                valueArray += "." + splitValue[i];
              }
              const array = getValue(form, valueArray);
              array.forEach((item, index) => {
                if (getValue(form, value.replace("index", index)) === "") {
                  response = false;
                }
              });
            } else {
              if (
                getValue(form, value) === "" ||
                getValue(form, value) === null ||
                getValue(form, value) === undefined
              ) {
                response = false;
              }
            }
          });
          setValue(newState, isCheckedValue, response);
        });
        if (
          [
            form.stoolScore.evaluation.s1.distribution,
            form.stoolScore.evaluation.s2.distribution,
            form.stoolScore.evaluation.s3.distribution,
            form.stoolScore.evaluation.s4.distribution,
            form.stoolScore.evaluation.s5.distribution,
          ]
            .map((item) => item || 0)
            .reduce((a, b) => +a + +b, 0) === 100
        ) {
          newState.stoolScore.checked = true;
        }
        return newState;
      });
    }
  }, [form, id]);

  useEffect(() => {
    const getChecklist = async () => {
      try {
        setLoading(true);
        const response = await api.get(`checklist/${id}`);
        response.data.date = convertDateToString(response.data.date);
        setForm(response.data);
        setLoading(false);
      } catch (e) {
        console.log(e);
      }
    };
    if (getData && id) {
      setGetData(false);
      setLoading(true);
      getChecklist();
    }
  }, [getData, id]);

  return (
    <Modal
      show={show}
      close={() => setShow(false)}
      header={
        <>
          <Icons type="checklist" color="#FC8535" size={30} />
          <h2>Checklist Confinamento</h2>
        </>
      }
      footer={
        <Button
          bg="default"
          border="default"
          color="white"
          onClick={saveChecklist}
          disabled={loading}
        >
          Salvar Checklist
        </Button>
      }
    >
      <Tabs
        value={tab}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="on"
        onChange={(e, value) => {
          setTab(value);
        }}
      >
        <Tab label="Dados Checklist" />
        {!loading && [
          <Tab label="Escritório" />,
          <Tab label="Fábrica" />,
          <Tab label="Manejo de Trato" />,
          <Tab label="Processamento de Grãos" />,
          <Tab label="Qualidade de Mistura" />,
          <Tab label="FDNfe Volumoso" />,
          <Tab label="Escore de Fezes" />,
          <Tab label="Qualidade de Água" />,
          <Tab label="Animais" />,
        ]}
      </Tabs>
      <Container>
        {tab === 0 && <ChecklistData form={form} setForm={setForm} />}
        {tab === 1 && <Office form={form} setForm={setForm} step="office" />}
        {tab === 2 && <Factory form={form} setForm={setForm} step="factory" />}
        {tab === 3 && (
          <TreatmentHandling
            form={form}
            setForm={setForm}
            step="treatmentHandling"
          />
        )}
        {tab === 4 && (
          <GrainProcessing
            form={form}
            setForm={setForm}
            step="grainProcessing"
          />
        )}
        {tab === 5 && (
          <MixingQuality form={form} setForm={setForm} step="mixingQuality" />
        )}
        {tab === 6 && (
          <BulkyNDFfe form={form} setForm={setForm} step="bulkyNDFfe" />
        )}
        {tab === 7 && (
          <StoolScore form={form} setForm={setForm} step="stoolScore" />
        )}
        {tab === 8 && (
          <WaterQuality form={form} setForm={setForm} step="waterQuality" />
        )}
        {tab === 9 && <Animals form={form} setForm={setForm} step="animals" />}
      </Container>
    </Modal>
  );
}
