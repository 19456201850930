import { std } from "mathjs";
import StringMask from "string-mask";

export const hasPermission = (userProfile, module, to) =>
  (userProfile.roles && userProfile.roles.indexOf("admin") > -1) ||
  (userProfile.modules && userProfile.modules[to].indexOf(module) > -1);

export const imgPreview = (img) => {
  try {
    return URL.createObjectURL(img);
  } catch (e) {
    return null;
  }
};

export const cpfMask = (value) => {
  return value.length > 14
    ? value
        .replace(/\D/g, "")
        .replace(/(\d{2})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d)/, "$1/$2")
        .replace(/(\d{4})(\d)/, "$1-$2")
        .replace(/(-\d{2})\d+?$/, "$1")
    : value
        .replace(/\D/g, "")
        .replace(/(\d{3})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d{1,2})/, "$1-$2")
        .replace(/(-\d{2})\d+?$/, "$1");
};

export const phoneNumberMask = (value) => {
  return value.length > 13
    ? value
        .replace(/\D/g, "")
        .replace(/(\d{0})(\d)/, "$1($2")
        .replace(/(\d{2})(\d)/, "$1)$2")
        .replace(/(\d{5})(\d)/, "$1-$2")
        .replace(/(-\d{4})\d+?$/, "$1")
    : value
        .replace(/\D/g, "")
        .replace(/(\d{0})(\d)/, "$1($2")
        .replace(/(\d{2})(\d)/, "$1)$2")
        .replace(/(\d{4})(\d)/, "$1-$2")
        .replace(/(-\d{4})\d+?$/, "$1");
};

export const percentage = (value) => {
  try {
    return value
      .replace(/[^0-9.,]*/g, "")
      .replace(/\.{2,}/g, ".")
      .replace(/\.,/g, ",")
      .replace(/\.[0-9]+\./g, ".")
      .replace(/(\d{1})(\d)/, "$1%");
  } catch (e) {
    return null;
  }
};

export const convertStringToDate = (string) => {
  try {
    const array = string.split("/");
    if (!array[2]) return null;
    return new Date(
      parseInt(array[2]),
      parseInt(array[1]) - 1,
      parseInt(array[0])
    );
  } catch (e) {
    return null;
  }
};
export const convertDateToString = (date) => {
  try {
    const dateArray = date.split("-");
    return `${dateArray[2].substr(0, 2)}/${dateArray[1]}/${dateArray[0]}`;
  } catch (e) {
    return null;
  }
};

export const getValue = (item, stringParams) => {
  try {
    if (!stringParams) return item;
    const params = stringParams.split(".");
    if (params.length === 6) {
      return item[params[0]][params[1]][params[2]][params[3]][params[4]][
        params[5]
      ];
    }
    if (params.length === 5) {
      return item[params[0]][params[1]][params[2]][params[3]][params[4]];
    }
    if (params.length === 4) {
      return item[params[0]][params[1]][params[2]][params[3]];
    }
    if (params.length === 3) {
      return item[params[0]][params[1]][params[2]];
    }
    if (params.length === 2) {
      return item[params[0]][params[1]];
    }
    if (params.length === 1) {
      return item[params[0]];
    }
  } catch (e) {
    return null;
  }
};

export const setValue = (item, stringParams, value) => {
  try {
    if (!stringParams) {
      item = value;
    }
    const params = stringParams.split(".");
    if (params.length === 6) {
      item[params[0]][params[1]][params[2]][params[3]][params[4]][params[5]] =
        value;
      return;
    }
    if (params.length === 5) {
      item[params[0]][params[1]][params[2]][params[3]][params[4]] = value;
      return;
    }
    if (params.length === 4) {
      item[params[0]][params[1]][params[2]][params[3]] = value;
      return;
    }
    if (params.length === 3) {
      item[params[0]][params[1]][params[2]] = value;
      return;
    }
    if (params.length === 2) {
      item[params[0]][params[1]] = value;
      return;
    }
    if (params.length === 1) {
      item[params[0]] = value;
      return;
    }
  } catch (e) {
    return null;
  }
};

export const getInitialObject = (model, data) => {
  if (data) {
    Object.keys(data).forEach((key) => {
      if (data[key] && typeof data[key] === "object") {
        Object.keys(data[key]).forEach((key2) => {
          if (data[key][key2] && typeof data[key][key2] === "object") {
            Object.keys(data[key][key2]).forEach((key3) => {
              if (
                data[key][key2][key3] &&
                typeof data[key][key2][key3] === "object"
              ) {
                Object.keys(data[key][key2][key3]).forEach((key4) => {
                  if (
                    data[key][key2][key3][key4] &&
                    typeof data[key][key2][key3][key4] === "object"
                  ) {
                    Object.keys(data[key][key2][key3][key4]).forEach((key5) => {
                      model[key][key2][key3][key4][key5] =
                        data[key][key2][key3][key4][key5];
                    });
                  } else {
                    model[key][key2][key3][key4] = data[key][key2][key3][key4];
                  }
                });
              } else {
                model[key][key2][key3] = data[key][key2][key3];
              }
            });
          } else {
            model[key][key2] = data[key][key2];
          }
        });
      } else {
        model[key] = data[key];
      }
    });
  }
  return model;
};

export const getScoreColor = (score) => {
  if (score >= 8) {
    return "#009877";
  } else if (score >= 6) {
    return "#007bff";
  } else if (score >= 4) {
    return "#d4a005";
  } else {
    return "#dc3545";
  }
};

export const getScoreFecesColor = (score) => {
  if (score >= 8) {
    return "#009877";
  } else if (score >= 6) {
    return "#007bff";
  } else if (score >= 3) {
    return "#ffc107";
  } else {
    return "#dc3545";
  }
};

export const getScoreText = (score) => {
  if (score >= 8) {
    return "Bom";
  } else if (score >= 6) {
    return "Regular";
  } else if (score >= 4) {
    return "Atenção";
  } else {
    return "Muita Atenção";
  }
};

export const getScoreFecesText = (score) => {
  if (score >= 8) {
    return "Bom";
  } else if (score >= 6) {
    return "Regular";
  } else if (score >= 3) {
    return "Atenção";
  } else {
    return "Muita Atenção";
  }
};

export const getScoreSlaughterColor = (score) => {
  if (score >= 20) {
    return "#dc3545";
  } else if (score >= 10) {
    return "#ffc107";
  } else if (score >= 5) {
    return "#007bff";
  } else {
    return "#009877";
  }
};

export const getScoreSlaughterText = (score) => {
  if (score >= 20) {
    return "Muita Atenção";
  } else if (score >= 10) {
    return "Atenção";
  } else if (score >= 5) {
    return "Regular";
  } else {
    return "Bom";
  }
};

export const getColor = (color, opacity = 1) => {
  if (!color) {
    var letters = "0123456789ABCDEF";
    color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(color)) {
    let c = color.substring(1).split("");
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return (
      "rgba(" +
      [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
      "," +
      opacity +
      ")"
    );
  }
  const o = Math.round,
    r = Math.random,
    s = 255;
  const colors = {
    dark: `rgba(52, 58, 64, ${opacity})`,
    success: `rgba(29, 201, 184, ${opacity})`,
    danger: `rgba(253, 57, 122, ${opacity})`,
    info: `rgba(85, 120, 235, ${opacity})`,
    warning: `rgba(255, 185, 34, ${opacity})`,
    primary: `rgba(0, 152, 111, ${opacity})`,
    random: `rgba(${o(r() * s)},${o(r() * s)},${o(r() * s)},${opacity})`,
  };

  return colors[color];
};

export const getFirstLetter = (name, index) => {
  if (!name) return "";
  const array = name.split(" ");
  let response = array[0].substr(0, 1).toUpperCase();
  if (array.length > 1) {
    response += array[index || array.length - 1].substr(0, 1).toUpperCase();
  }
  return response;
};

export const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const getTotalScore = (birds) =>
  birds.map(({ total }) => total).reduce((a, b) => a + b) / birds.length;

const convertMsToDays = (ms) => {
  const msInOneSecond = 1000;
  const secondsInOneMinute = 60;
  const minutesInOneHour = 60;
  const hoursInOneDay = 24;

  const minutesInOneDay = hoursInOneDay * minutesInOneHour;
  const secondsInOneDay = secondsInOneMinute * minutesInOneDay;
  const msInOneDay = msInOneSecond * secondsInOneDay;

  return Math.ceil(ms / msInOneDay);
};

export const getDaysBetweenDates = (dateOne, dateTwo) => {
  try {
    let differenceInMs = dateTwo.getTime() - dateOne.getTime();

    if (differenceInMs < 0) {
      differenceInMs = dateOne.getTime() - dateTwo.getTime();
    }

    return convertMsToDays(differenceInMs) - 1;
  } catch (e) {
    return null;
  }
};

export function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

export const getAverage = ({ start, mid, end }) => {
  try {
    return (
      Math.round(([start, mid, end].reduce((a, b) => +a + +b, 0) / 3) * 100) /
      100
    );
  } catch (e) {
    return 0;
  }
};

export const getStandartDeviation = ({ start, mid, end }) => {
  try {
    return Math.round(std(+start, +mid, +end) * 100) / 100;
  } catch (e) {
    return 0;
  }
};

export const getPercentBulkyNDFfe = (param, form) => {
  if (
    !form.bulkyNDFfe.d19mm ||
    !form.bulkyNDFfe.d8mm ||
    !form.bulkyNDFfe.d4mm ||
    !form.bulkyNDFfe.depth
  )
    return 0;
  return (
    +form.bulkyNDFfe[param].toString().replace(",", ".") /
    (+form.bulkyNDFfe.d19mm.toString().replace(",", ".") +
      +form.bulkyNDFfe.d8mm.toString().replace(",", ".") +
      +form.bulkyNDFfe.d4mm.toString().replace(",", ".") +
      +form.bulkyNDFfe.depth.toString().replace(",", "."))
  );
};

export const convertNumberToString = (number, decimals) => {
  try {
    if (!number) return 0;
    let arrayDecimals = "";
    if (decimals) {
      arrayDecimals = ",";
      for (let i = 0; i < decimals; i++) {
        arrayDecimals += "0";
      }
    }

    return new StringMask("#.##0" + arrayDecimals, { reverse: true }).apply(
      (Math.round(number * 100) / 100).toFixed(decimals || 0).replace(".", "")
    );
  } catch (e) {
    return 0;
  }
};

export const getBase64FromUrl = async (url) => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
  });
};
