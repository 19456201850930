import styled from "styled-components";

export const Container = styled.div`
  background-image: url("https://zenbytes-public.sfo2.digitaloceanspaces.com/mcassab/bg-login.jpg");
  flex: none;
  max-width: 100%;

  margin: 0;
  background-size: cover;
  background-position: 50%;
  > div {
    padding: 2rem 0;
    display: flex;

    background: rgb(252 133 53 / 90%); /* fallback for old browsers */
    background: -webkit-linear-gradient(
      to right,
      rgb(0 152 112 / 90%),
      rgb(252 133 53 / 90%)
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
      to right,
      rgb(0 152 112 / 90%),
      rgb(252 133 53 / 90%)
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  }
`;

export const Content = styled.div`
  width: 100%;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 1025px) {
    padding: 0 30px;
  }
  @media (min-width: 1439px) {
    width: 1380px;
    margin: 0 auto;
  }
  img {
    height: 25px;
    padding-bottom: 3px;
  }
`;
export const Copyright = styled.div`
  margin: 0;
  padding: 0 1.25rem 0 1rem;
  font-size: 1rem;
  font-weight: 400;
  color: #fff;
  a {
    transition: color 0.3s ease;
  }
`;
