import { useContext, useState, useEffect } from "react";
import UserProfileContext from "../../../../../contexts/profile";
import Swal from "sweetalert2";
import { api } from "../../../../../services/api";
import { Icons } from "../../../../../components/Icons";
import { SubHeader } from "../../../../../components/SubHeader";
import { Input } from "../../../../../components/Input";
import {
  Block,
  BlockBody,
  BlockHeader,
  Col,
  Row,
  Separator,
} from "../../../../../styles";
import { Button } from "../../../../../components/Button/style";

export function Profile(props) {
  const profile = useContext(UserProfileContext);

  const [userData, setUserData] = useState({
    name: "",
    responsibility: "",
    username: "",
    code: "",
  });

  const editUserData = async (e) => {
    e.preventDefault();
    const requestUserData = { ...userData };
    const response = await Swal.fire({
      title: "Editar Dados",
      text: "Deseja confirmar edição dos dados",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0451e8",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, Editar Dados!",
      showLoaderOnConfirm: true,
      preConfirm: async () =>
        await api.post("auth/userprofile", requestUserData).catch((err) => ({
          err: true,
          data: { message: err.response.data.message },
        })),
    });

    if (response.value) {
      Swal.fire({
        title: "Editar Dados",
        text: response.value.data.message,
        icon: response.value.err ? "error" : "success",
        preConfirm: () =>
          !response.value.err ? window.location.reload() : null,
      });
    }
  };

  useEffect(() => {
    const loadUserData = () => {
      setUserData({ ...profile });
    };
    if (profile._id) {
      loadUserData();
    }
  }, [profile]);

  return (
    <>
      <SubHeader
        {...props}
        title="Meus Dados"
        breadcrumbs={[
          {
            label: "Meus Dados",
          },
        ]}
        icon={<Icons type="profile" size="24" color="#fff" />}
      />
      <Block>
        <BlockHeader>{profile.name}</BlockHeader>
        <BlockBody>
          <form onSubmit={editUserData}>
            <Row>
              <Col>
                <Input
                  item={userData}
                  params="code"
                  label="Código"
                  disabled={true}
                  setItem={setUserData}
                  type="input"
                />
              </Col>
              <Col>
                <Input
                  item={userData}
                  params="name"
                  capitalize
                  label="Nome"
                  setItem={setUserData}
                  type="input"
                />
              </Col>
              <Col>
                <Input
                  item={userData}
                  inputType="email"
                  params="username"
                  label="E-Mail"
                  setItem={setUserData}
                  type="input"
                />
              </Col>
              <Col>
                <Input
                  item={userData}
                  params="responsibility"
                  capitalize
                  label="Cargo"
                  setItem={setUserData}
                  type="input"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Separator />
              </Col>
            </Row>
            <Row>
              <Col style={{ alignItems: "center" }}>
                <Button
                  type="submit"
                  color="white"
                  bg="default"
                  border="default"
                >
                  Salvar
                </Button>
              </Col>
            </Row>
          </form>
        </BlockBody>
      </Block>
    </>
  );
}
