import { createRef, useContext } from "react";
import {
  Container,
  UserDataContent,
  UserDataDropdown,
  UserDataDropdownToggle,
  UserDataIcon,
  UserDataUsername,
  UserDataWelcome,
} from "./style";
import ProfileContext from "../../../../../contexts/profile";
import { getFirstLetter } from "../../../../../utils/globalFunctions";

export function MenuRight({ setShowSideMenuRight }) {
  const ref = createRef();

  const profile = useContext(ProfileContext);

  return (
    <Container ref={ref}>
      <UserDataContent>
        <UserDataDropdown>
          <UserDataDropdownToggle
            onClick={() =>
              setShowSideMenuRight((prevState) =>
                prevState === "true" ? "" : "true"
              )
            }
          >
            <UserDataWelcome>Olá</UserDataWelcome>
            <UserDataUsername>{profile.name?.split(" ")[0]}</UserDataUsername>
            <UserDataIcon>
              <b>{getFirstLetter(profile.name)}</b>
            </UserDataIcon>
          </UserDataDropdownToggle>
        </UserDataDropdown>
      </UserDataContent>
    </Container>
  );
}
