import MenuBottom from "./MenuBottom";
import { MenuRight } from "./MenuRight";
import { Brand, Container, Content, Logo, LogoLink } from "./style";

export function Header(props) {
  return (
    <>
      <Container className="animate__animated animate__fadeInDown">
        <Content>
          <Brand>
            <LogoLink to="/">
              <Logo
                alt="Logo"
                src="https://zenbytes-public.sfo2.digitaloceanspaces.com/mcassab-bovinos/logo-mcassab-branca.png"
              />
            </LogoLink>
          </Brand>
          <MenuRight {...props}></MenuRight>
        </Content>
      </Container>
      <MenuBottom {...props} />
    </>
  );
}
