import { useEffect, useState } from "react";
import { isAuthenticated, logout } from "../../services/auth";
import Profile from "../../contexts/profile";
import UpdateListContext from "../../contexts/updateList";
import { Redirect } from "react-router-dom";
import { Container, Content } from "./style";
import { Header } from "./Common/Header";
import { api } from "../../services/api";
import { Footer } from "./Common/Footer";
import { SideMenuRight } from "./Common/Header/MenuRight/SideMenuRight";
import { Routes } from "./Routes";

export function App(props) {
  const [profile, setProfile] = useState({});

  const [showSideMenu, setShowSideMenu] = useState("");

  const [showSideMenuRight, setShowSideMenuRight] = useState("");

  const [updateList, setUpdateList] = useState(false);

  useEffect(() => {
    const loadUser = async () => {
      const response = await api.get("/auth/userprofile").catch((err) => err);
      if (!response.data) {
        return logout();
      }

      setProfile(response.data);
    };
    loadUser();
  }, []);

  return isAuthenticated() ? (
    <Profile.Provider value={profile}>
      <UpdateListContext.Provider
        value={{
          updateList,
          setUpdateList,
        }}
      >
        <Container>
          <Content>
            <Header
              showSideMenu={showSideMenu}
              setShowSideMenu={setShowSideMenu}
              showSideMenuRight={showSideMenuRight}
              setShowSideMenuRight={setShowSideMenuRight}
              {...props}
            />
            <Routes />
            <Footer />
          </Content>
        </Container>
        <SideMenuRight
          showSideMenuRight={showSideMenuRight}
          setShowSideMenuRight={setShowSideMenuRight}
        />
      </UpdateListContext.Provider>
    </Profile.Provider>
  ) : (
    <Redirect to="/login" />
  );
}
