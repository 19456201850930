import { Icons } from "../../../../../../components/Icons";
import { SubHeader } from "../../../../../../components/SubHeader";
import { Table } from "../../../../../../components/Table";

export function ListUsers(props) {
  const tableParams = [
    {
      key: "code",
      label: "Código",
      filter: "text",
      placeholder: "Digite o Código",
      mobile: {
        type: "title",
      },
    },
    {
      key: "name",
      label: "Nome",
      filter: "text",
      placeholder: "Digite o Nome",
      mobile: {
        type: "title",
      },
    },
    {
      key: "username",
      label: "E-mail",
      filter: "text",
      placeholder: "Digite o E-mail",
      mobile: {
        type: "small",
      },
    },
    {
      key: "city",
      label: "Cidade",
      filter: "text",
      placeholder: "Digite o Cidade",
      mobile: {
        type: "item",
      },
    },
    {
      key: "state",
      label: "Estado",
      filter: "text",
      placeholder: "Digite o Estado",
      mobile: {
        type: "item",
      },
    },
    {
      key: "responsibility",
      label: "Cargo",
      filter: "text",
      placeholder: "Digite o Cargo",
      mobile: {
        type: "small",
      },
    },
    {
      key: "status",
      type: "status",
      label: "Status",
      filter: "status",
      mobile: {
        type: "status",
      },
    },
  ];

  return (
    <>
      <SubHeader
        {...props}
        title="Usuários"
        toolbar={["exportPDF"]}
        route="user"
        newLabel="Novo Usuário"
        newLink="/basicregistration/user/create"
        breadcrumbs={[
          { label: "Cadastro Base" },
          {
            label: "Usuários",
          },
        ]}
        icon={<Icons type="users" size="24" color="#fff" />}
      />
      <Table
        cols={tableParams}
        editLink={"/basicregistration/user/edit/"}
        emptyText={"Nenhum usuário localizado!"}
        route={"user"}
        {...props}
      />
    </>
  );
}
