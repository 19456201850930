import React, { useState } from "react";
import { Title } from "../style";
import { Tab, Tabs } from "@material-ui/core";
import { Evaluation } from "../Evaluation";

export function TreatmentHandling({ form, setForm, step }) {
  const [tab, setTab] = useState(0);

  const evaluationConfig = [
    {
      label: "Dieta correta",
      key: "correctDiet",
    },
    {
      label: "Curral correto",
      key: "correctCorral",
    },
    {
      label: "Quantidade correta",
      key: "correctAmount",
    },
    {
      label: "Horário Correto",
      key: "correctTime",
    },
    {
      label: "Da maneira correta",
      key: "inTheRightWay",
    },
    {
      label: "Segregação de dieta",
      key: "dietSegregation",
    },

    {
      label: "Avaliação Geral Manejo de trato",
    },
  ];

  return (
    <>
      <Title>Avaliação Manejo de Trato</Title>
      <Tabs
        value={tab}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="on"
        onChange={(e, value) => {
          setTab(value);
        }}
      >
        {evaluationConfig.map(({ label }) => (
          <Tab label={label} />
        ))}
      </Tabs>
      <Evaluation
        {...{ form, setForm, tab, evaluationConfig, step }}
        id={form._id}
      />
    </>
  );
}
